import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../components';
import { useTranslation } from 'react-i18next';
import Wizard from './wizard';
import './styles.scss';

const CompanyWizard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'companyPage',
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;

  return (
    <React.Fragment>
      <Breadcrumb
        heading={t('add_new_company')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/company');
        }}
      />
      <Wizard update={update} id={id} />
    </React.Fragment>
  );
};

export default CompanyWizard;
