import React, { useState } from 'react';
import Popover from 'antd/es/popover';
import { Radio, Select } from 'antd';
import './inventoryFilter.scss';

type Props = {
  children: any;
  onApply: any;
  open: boolean;
  onClose: any;
  onReset?: any;
};

const InventoryFilter = (props: Props) => {
  let { children, onApply, open, onClose, onReset } = props;
  const [columnName, setColumnName] = useState<any>({});
  const [sort, setSort] = useState(2);
  const buttons = [
    { label: 'A to Z', value: 1 },
    { label: 'Z to A', value: 2 },
  ];

  const item1 = [
    // { label: 'Sku', value: 1, key: 'sku_id' },
    { label: 'Calorie Counted', value: 2, key: 'is_verified' },
    { label: 'Ingredient Type', value: 3, key: 'ingredient_type' },
    // { label: 'Unit', value: 4, key: 'unit_id' },
  ];

  return (
    <>
      <Popover
        placement="bottom"
        open={open}
        onOpenChange={() => onClose(!open)}
        content={
          <div>
            <div className="inventoryFilter">
              <Select
                size={'large'}
                placeholder={'Select Column'}
                onChange={(e) => {
                  // console.log(e);
                  // console.log(item1);

                  setColumnName(item1?.find((item: any) => item?.value === +e));
                  // setColumnName(e);
                }}
                style={{ width: 180 }}
                optionFilterProp="children"
                value={columnName?.value || null}
                // options={item1}
              >
                {item1?.map((data: any, index: number) => (
                  <Select.Option key={index} value={data?.value}>
                    {data?.label}
                  </Select.Option>
                ))}
              </Select>
              <Radio.Group
                options={buttons}
                onChange={(e) => {
                  // console.log(e, e.target.value);
                  setSort(e.target.value);
                }}
                value={sort}
                optionType="button"
                className="inventoryFilter-Radio"
              />
              {/* <Checkbox
          // onChange={(e: any) => onSelectAllIngredients(e, ingredientList)}
          // checked={visible}
          >
            Verified
          </Checkbox> */}
            </div>
            <div className="inventoryFilter-Apply-container">
              <div
                className="reset-filter-button"
                onClick={() => {
                  setColumnName({});
                  setSort(1);
                  onReset();
                }}
              >
                Reset
              </div>
              <div
                className="inventoryFilter-Apply"
                onClick={() => {
                  let sortField;
                  let sortOrder;
                  switch (columnName?.key) {
                    case 'is_verified':
                      sortField = ['is_verified', 'ingredient_name'];
                      break;
                    case 'ingredient_type':
                      sortField = ['ingredient_type', 'ingredient_name'];
                      break;
                    default:
                      sortField = ['ingredient_name'];
                  }
                  if (sort === 2) {
                    sortOrder = ['desc', 'asc'];
                  } else {
                    sortOrder = ['asc', 'desc'];
                  }
                  onApply(sortField, sortOrder);
                }}
              >
                Apply
              </div>
            </div>
          </div>
        }
        title="Filter"
        trigger="click"
      >
        {children}
      </Popover>
      {/* <Select
        size={'large'}
        placeholder={'Select Column'}
        onChange={(e) => {
          console.log(e);
          setColumnName(e);
        }}
        style={{ width: 180 }}
        // optionFilterProp="children"
        value={columnName}
        options={item1}
      /> */}
    </>
  );
};

export default InventoryFilter;
