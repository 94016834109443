/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  // Select,
  Space,
  Dropdown,
  Checkbox,
  Input,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Page,
  Pagination,
  Breadcrumb,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
  Button,
  DetailDialog,
  Loader,
} from '../../components';
// import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import * as Filesaver from 'file-saver';
import Excel from 'exceljs';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import Edit from '../../assets/images/Edit.png';
import Print from '../../assets/images/Print.png';
import moment from 'moment';
import SupplierApi from '../../redux/middleware/supplier';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Utils from '../../redux/utils';
import './StockTaking.scss';
import { formatNumber, handleFloat } from '../../helper/sortData';
import { checkAuthentication } from '../../helper/checkAuthentication';

const StockTaking = () => {
  const navigate = useNavigate();

  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [stockCount, setStockCount] = useState([{}]);
  const [ingredientList, setIngredientList] = useState([]);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalIngredients, setTotalIngredients] = useState(0);
  const [stockLoader, setStockLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const PageDataLength = [6, 10, 20, 50];
  const [loader, setLoader] = useState(false);
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [ingredients, setIngredients] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const [stockCountRecord, setStockCountRecord] = useState<any>({});
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  // const { currentCompany } = useSelector((state: any) => state.Auth);
  const { currentBrand } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentCompany]);
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Stock Taking'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
    fetchStatus();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  useEffect(() => {
    if (saveTrigger) {
      SubmitStockCount();
    }
  }, [saveTrigger]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  //   {
  //     key: '2',
  //     label: <div className="dotOption">Edit</div>,
  //     icon: <img src={Edit} width={15} />,
  //   },
  // ];
  const itemsForStock: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Update Stock</div>,
      icon: <img src={Edit} width={15} className="iconDropdown" />,
    },
  ];

  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(
        data?.find((type: any) => type?.slug == 'stock_adjustment')
      );

    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  //   const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //     let option = +e.key;
  //     switch (option) {
  //       case 1:
  //         setLoader(true);
  //         fetchDialogData();
  //         break;
  //       case 2:
  //         // setUpdate(true);
  //         // setOpenIngredientDialog(true);
  //         break;
  //       default:
  //     }
  //   };

  const fetchDialogData = async (id: any) => {
    const { data, success } = await SupplierApi.getInventorySupplyById(id);
    setLoader(false);
    if (success) {
      setDialogData(data);
      setStockCountRecord(id);
      setOpenDialog(true);
    }
  };

  const handleChangeData = (value: any, index: any) => {
    const ingredientsReplicate = dialogData?.ingredients;
    ingredientsReplicate[index] = {
      ...ingredientsReplicate[index],
      transfer_quantity: value,
      deficit:
        +ingredientsReplicate[index]?.current_balance > value
          ? +ingredientsReplicate[index]?.current_balance - +value
          : 0,
      cash_impact:
        +ingredientsReplicate[index]?.current_balance > value
          ? (+ingredientsReplicate[index]?.current_balance - +value) *
            ingredientsReplicate[index]?.unit_cost
          : 0,
    };
    setDialogData({ ...dialogData, ingredients: ingredientsReplicate });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>STOCK COUNT ID</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">{record?.id}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any, index: number) => (
        <div
          className={`requestStatusContainer ${
            record?.request_status?.toLowerCase() == 'pending'
              ? 'inventoryTransfer-request-pending'
              : record?.request_status?.toLowerCase() == 'approved'
              ? 'inventoryTransfer-request-approve'
              : 'inventoryTransfer-request-reject'
          } `}
        >
          {record?.request_status}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>RAISED BY</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-override-column2-style">
          {record?.po_from}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CASH VALUE</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-override-column2-style">
          {formatNumber(record?.total_cash_impact)}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="col3 cellSpacing stockCount-override-column2-style">
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any) =>
        // <Dropdown
        //   menu={{
        //     onClick: handleDropDownClick,
        //     items: itemsForStock,
        //   }}
        //   trigger={['click']}
        //   placement="bottomRight"
        //   arrow={{ pointAtCenter: true }}
        // >
        //   <a
        //     onClick={() => {
        //       setStockCountRecord(record?.id);
        //     }}
        //   >
        //     <Space>
        //       <div className="dots">
        //         <img src={More} />
        //       </div>
        //     </Space>
        //   </a>
        // </Dropdown>
        record?.request_status?.toLowerCase() !== 'received' && (
          <div
            onClick={() => {
              setLoader(true);
              fetchDialogData(record?.id);
            }}
            className="update-stock-button"
          >
            Enter Stock
          </div>
        ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div
          onClick={() => {
            exportToExcel(record?.id);
          }}
          className="update-stock-button"
        >
          <span>
            <img src={Print} width={15} className="iconDropdown" />
          </span>
          Print Sheet
        </div>
      ),
    },
  ];

  const unTaggedColumns = [
    {
      title: (_: any, record: any) => (
        <div className="tableTitle">
          <Checkbox
            onChange={(e: any) => onSelectAllIngredients(e, ingredientList)}
            checked={visible}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacing">
          {' '}
          <Checkbox
            onChange={(e: any) => onChangeIngredients(e, record)}
            checked={
              ingredients?.findIndex(
                (ingredient: any) => ingredient?.id == record?.id
              ) != -1
            }
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font ">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title stockCount-alignment-left">
                  {record?.ingredient_name}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CURRENT BALANCE</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-storageInput">
          {/* <Input
            placeholder="Enter Cost"
            type="number"
            value={recordIndex == index && totalCost}
            disabled
            onChange={(e) => setTotalCost(+e.target.value)}
          /> */}
          {handleFloat(record?.current_balance)}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.storage_unit}</div>
      ),
    },
  ];

  const exportToExcel = async (id: any) => {
    const brand = await Utils.getCurrentBrand();
    const { data, success } = await SupplierApi.getInventorySupplyById(id);
    if (success) {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');
      worksheet.getCell('A1').value = 'Brand';
      worksheet.getCell('B1').value = 'LilChick Brand';
      worksheet.getCell('A2').value = 'Created At';
      worksheet.getCell('B2').value = '23-06-2023';
      worksheet.getCell('A3').value = 'Stock Transfer ID';
      worksheet.getCell('B3').value = data?.id;

      worksheet.getRow(5).values = [
        'Ingredient Name',
        'Current Balance',
        'Unit',
        'Updated Stock',
      ];
      worksheet.columns = [
        { key: 'ingredient_name', width: 32 },
        { key: 'current_balance', width: 15 },
        { key: 'unit', width: 10 },
        { key: 'request_quantity', width: 15 },
      ];

      data?.ingredients?.forEach((ingredient: any) => {
        worksheet.addRow({
          ingredient_name: ingredient?.ingredient_name,
          current_balance: ingredient?.current_balance,
          unit: ingredient?.storage_unit,
          request_quantity: ingredient?.request_quantity || '',
        });
      });

      worksheet.eachRow(function (row) {
        row.eachCell(function (cell: any) {
          worksheet.getCell(cell['_address']).border = {
            top: { style: 'thin', color: { argb: '00000000' } },
            left: { style: 'thin', color: { argb: '00000000' } },
            bottom: { style: 'thin', color: { argb: '00000000' } },
            right: { style: 'thin', color: { argb: '00000000' } },
          };
          worksheet.getCell(cell['_address']).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        });
      });

      let tableRows = '';
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 4) {
          tableRows += '<tr>';
          row.eachCell((cell) => {
            tableRows += `<td>${cell.value}</td>`;
          });
          tableRows += '</tr>';
        }
      });

      let html = `
    <html>
    <head>
        <title>Stock Taking ${data?.id}</title>
        <style>
            body {
                display: flex;
            }
            .container {
                width: 50%;
                padding: 10px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                table-layout: fixed;
            }
            table, th, td {
                border: 1px solid black;
            }
            th, td {
                padding: 8px;
                text-align: center;
                word-wrap: break-word;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <h1>Stock Taking</h1>
            <p>Brand: ${brand?.brand_name}</p>
            <p>Created At: ${getDateOnly(data?.created_at)}</p>
            <p>Stock Transfer ID: ${data?.id}</p>
            <table>
                <tbody>
                    ${tableRows}
                </tbody>
            </table>
        </div>
        <div class="container">
            <iframe id="printIframe" style="border:none;width:100%;height:100%"></iframe>
        </div>
        <script>
            const iframe = document.getElementById('printIframe');
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(\`
                <html>
                <head>
                    <title>Stock Taking Print View</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            table-layout: fixed;
                        }
                        table, th, td {
                            border: 1px solid black;
                        }
                        th, td {
                            padding: 8px;
                            text-align: center;
                            word-wrap: break-word;
                        }
                    </style>
                </head>
                <body>
                    <h1>Stock Taking Print View</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Ingredient Name</th>
                                <th>Current Balance</th>
                                <th>Unit</th>
                                <th>Updated Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${tableRows}
                        </tbody>
                    </table>
                </body>
                </html>\`);
            iframeDoc.close();
            iframe.onload = function() {
                iframe.contentWindow.print();
            };
        </script>
    </body>
    </html>
    `;

      const printTab = window.open('', '_blank');
      if (printTab) {
        printTab.document.write(html);
        printTab.document.close();
      } else {
        console.error('Failed to open print tab');
      }
    }
  };

  function getDateOnly(dateTimeString: any) {
    const [date] = dateTimeString.split('T');
    return date;
  }

  const onSelectAllIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e?.target?.checked) {
      setVisible(true);
      setIngredients(data);
    } else {
      setVisible(false);
      setIngredients([]);
    }
  };

  const onChangeIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e?.target?.checked) {
      setIngredients([...ingredients, data]);
    } else {
      setIngredients(
        ingredients?.filter((ingredient: any) => ingredient?.id != data?.id)
      );
    }
  };

  const calculateTotalCashImpact = (ingredients: any[]) => {
    return ingredients.reduce((partialSum: number, data: any) => {
      const cashImpact = Math.round(parseFloat(data?.cash_impact));
      return partialSum + cashImpact;
    }, 0);
  };

  const pageData = async () => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'stock_adjustment'
      );
    const imagesData = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );

    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      setTotal(imagesData?.data?.pagination?.total);
      setStockCount(imagesData?.data?.items);
    }
    setStockLoader(false);
  };

  const onSearch = async (keyword: string) => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'stock_adjustment'
      );
    const imagesData = await SupplierApi.getInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : ''
    );

    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      setTotal(imagesData?.data?.pagination?.total);
      setStockCount(imagesData?.data?.items);
    }
    setStockLoader(false);
  };

  const SubmitStockCount = async (update?: boolean) => {
    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const role_id = user?.roles[0]?.id;

    const payload: any = {
      request_type_id: update ? dialogData?.request_type_id : requestType?.id,
      request_status_id: saveTrigger ? 9 : 8, // update ? dialogData?.request_status_id : status?.id,
      brand_id: update ? dialogData?.brand_id : brand?.brand_id,
      shop_id: update ? dialogData?.shop_id : shop?.shop_id,
      company_id: update ? dialogData?.company_id : company?.id,
      created_by: user?.id,
      total_cash_impact: update
        ? calculateTotalCashImpact(dialogData?.ingredients || [])
        : // dialogData?.ingredients
          //     .map((data: any) => +data?.transfer_quantity * +data?.deficit)
          //     .reduce((partialSum: any, a: any) => partialSum + a, 0)
          0,
      po_from: saveTrigger
        ? dialogData?.po_from
        : update
        ? dialogData?.po_from
        : role_id == 4
        ? 'shop'
        : role_id == 5
        ? 'brand'
        : '',
      ingredients:
        update || saveTrigger
          ? dialogData?.ingredients?.map((data: any) => ({
              ingredient_id: data?.ingredient_id || data?.id,
              request_quantity: data?.transfer_quantity || 0,
              deficit: data?.deficit,
              cash_impact: Math.round(parseFloat(data?.cash_impact)),
            }))
          : ingredients?.map((data: any) => ({
              ingredient_id: data?.ingredient_id || data?.id,
              request_quantity: 0,
              deficit: 0,
              cash_impact: 0,
            })),
    };

    try {
      let responseDialogData;
      setLoader(true);
      const response =
        update || saveTrigger
          ? await SupplierApi.updateInventorySupply(payload, stockCountRecord)
          : await SupplierApi.createInventorySupply(payload);
      setLoader(false);
      setIngredients([]);
      if (response == undefined || !response?.success) {
        responseDialogData = {
          OnClick: () => {
            setOpenDialog(false);
          },
          type: 'error',
          messageArray: [
            // `Your Stock Count`,
            // ` has not been ${update ? 'Updated' : 'Created'}`,
            response?.error?.request_status_id,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        pageData();
        setOpenDialog(false);
        // navigate('/app/stockTaking');
        // if (update) {
        //   setOpenDialog(false);
        //   pageData();
        // } else setCheck(true);
        // let responseDialogData = {
        //   OnClick: () => {
        //     if (update) {
        //       setOpenDialog(false);
        //       pageData();
        //     } else setCheck(true);
        //   },
        //   type: 'success',
        //   messageArray: [
        //     `Your Stock Count`,
        //     ` has been ${update ? 'Updated' : 'Created'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // const actionButton = (
  //   <Button
  //     text="Add New"
  //     className="theme-button recipeBtn"
  //     icon={<PlusOutlined />}
  //     handleButtonClick={() => {
  //       navigate('wizard', { state: { update: false, id: '' } });
  //     }}
  //     shape="round"
  //     size="middle"
  //   />
  // );

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Stock Taking"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/dashboard');
        }}
        button={() => {}}
        perm_status={permissionStatus}
      />
      {openDialog && (
        <DetailDialog
          open={openDialog}
          handleClose={handleClose}
          data={dialogData}
          handleChangeData={handleChangeData}
          component={'stock_count'}
          handleResponse={SubmitStockCount}
          onSaveClick={() => {
            setSaveTrigger(true);
          }}
        />
      )}
      <Spin spinning={loader} size="large">
        <Page title="Stock Taking">
          {/* <div className="stockCountHeading">
          <p className="heading"> Supplier Name:</p>
          <h1 className="name">{supplier?.supplier_name}</h1>
        </div> */}
          <Row className="listingTopFilter">
            <Col lg={24} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox action={onSearch} placeholderText="Search..." />
                </div>

                {!!ingredients.length && (
                  <Button
                    text="Submit"
                    className="theme-button recipeBtn"
                    // icon={<PlusOutlined />}
                    handleButtonClick={() => {
                      SubmitStockCount(false);
                    }}
                    shape="round"
                    size="middle"
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={16} className="StockTaking-list-wrapper">
              {!stockLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={''}
                  dataSource={[...stockCount]}
                  columns={taggedColumns}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin
                    spinning={stockLoader}
                    className="recipeLoader"
                    size="large"
                  />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};
export default StockTaking;
