/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Spin, Input, Select, SelectProps } from 'antd';
import BuyingUnitActions from '../../redux/middleware/buyingUnit';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import ResponseDialog from '../ResopnseDialog';
import { useTranslation } from 'react-i18next';
import Utils from '../../redux/utils';
import './styles.scss';

type Props = {
  handleCancel: any;
  open: boolean;
  component?: string;
  buyingUnit: string;
  supplierId: number;
  supplier: string;
  onSubmit?: any;
};

const BuyingUnitModal = (props: Props) => {
  let { handleCancel, open, buyingUnit, supplierId, supplier, onSubmit } =
    props;
  const [loader, setLoader] = useState(false);
  const [unit, setUnit] = useState<any>([]);
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: 0, key: 0 },
  ]);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'buyingUnitPage',
  });

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = async () => {
    const data = await SubRecipeActions.getStorageUnits();
    const option: SelectProps['options'] = data?.data?.map((unit: any) => {
      return {
        value: unit.id,
        label: unit.storage_unit,
        key: unit.id,
      };
    });
    setOption(option);
  };

  const handleChangeUOM = (value: string | string[]) => {
    setUnit(value);
  };

  const handleSubmit = async () => {
    const company = await Utils.getCurrentCompany();
    const param = {
      company_id: company?.id,
      buying_unit: buyingUnit,
      supplier_id: supplierId,
      storage_unit: unit,
    };
    try {
      const response = await BuyingUnitActions.addBuyingUnit(param);
      if (response?.success) {
        handleCancel(false);
        onSubmit();
      } else {
        setLoader(false);
        let responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Failed!, Try Again'],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (error) {
      console.error('Error adding new buying unit:', error);
    }
  };

  return (
    <Modal
      title={<div className="title font">Buying Unit</div>}
      centered
      open={open}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Spin spinning={loader} size="large" className="loaderWidthHeight">
        <Row>
          <Col lg={24} md={24}>
            <div className="ingredientCategoryContainerWidth">
              <div className="wizardSubContainer">
                <div className="wizardLabel buying-unit-modal">
                  {t('supplier_label')} <span className="sterik">*</span>
                </div>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('select_placeholder')}
                  value={supplier}
                  disabled={true}
                />
              </div>
              <div className="wizardSubContainer">
                <div className="wizardLabel buying-unit-modal">
                  {t('buying_unit_label')} <span className="sterik">*</span>
                </div>
                <Input
                  placeholder={t('input_placeholder')}
                  size={'large'}
                  style={{ width: '100%' }}
                  disabled={true}
                  value={buyingUnit}
                />
              </div>
              <div className="wizardSubContainer">
                <div className="wizardLabel buying-unit-modal">
                  {t('storage_unit_label')} <span className="sterik">*</span>
                </div>
                <Select
                  mode="multiple"
                  size={'large'}
                  placeholder="Please Select Storage Unit"
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={option}
                  value={unit?.label}
                  className={
                    errorList['storage_unit']?.length > 0
                      ? 'createIngredientBorderRed'
                      : ''
                  }
                />
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['storage_unit']}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <div className="butonGroup buying-unit-btn">
          <div className="wizard-button" onClick={() => handleCancel(false)}>
            Cancel
          </div>
          <div className="continue wizard-button" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default BuyingUnitModal;
