/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Input, Select, Spin, Button, Space, Divider } from 'antd';
import Delete from '../../assets/images/Delete.svg';
import ComboActions from '../../redux/middleware/combo';
import Utils from '../../redux/utils';
import './styles.scss';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import { formatFileSize } from 'react-papaparse';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useSelector } from 'react-redux';

type Props = {
  stepper: number;
  getBase64: any;
  handleBackStepper: any;
  handleCombo: any;
  handleDescription: any;
  handleImage: any;
  image: string;
  combo: string;
  description: string;
  comboIngredient: any;
  addComboMenu: any;
  deleteComboMenu: any;
  handleChange: any;
  handleChangeValue: any;
  handleChangePrice?: any;
  handleServing: any;
  serving: any;
  handleCostRatio: any;
  costRatio: any;
  proposedPrice: any;
  totalCost: any;
  finalPrice: any;
  handleFinalPrice: any;
  errorList: any;
  errorStatus: any;
  handleChangeCategory?: any;
  comboSizes?: any;
  comboGroups?: any;
  newSize?: any;
  newGroup?: any;
  selectedSizes?: any;
  selectedGroups?: any;
  handleGroupChange?: any;
  handleSizeChange?: any;
  handleNewSize?: any;
  handleNewGroup?: any;
  handleCreateSize?: any;
  handleCreateGroup?: any;
  handleCostIngredient?: any;
  handleTotalPrice?: any;
  update?: any;
};

const ComboStep = (props: Props) => {
  const brand = Utils.getCurrentBrand();
  const { integrations } = Utils.getCurrentBrand();
  const isFoodicsIntegrated = integrations.length > 0;
  const [menu, setMenu] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<any>([]);
  const [quantityValue, setQuantityValue] = React.useState();
  const [recipeLoader, setRecipeLoader] = React.useState(false);
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const inputRef = useRef<InputRef>(null);
  const { TextArea } = Input;

  const fetchRecipeData = async (menu_category: any) => {
    setRecipeLoader(true);
    const recipeData: any = await ComboActions.getRecipesForCombo(
      menu_category
    );
    setMenu(recipeData?.data.items);
    setRecipeLoader(false);
  };

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      const parentCategory = data.find(
        (categoryObj: any) => categoryObj?.slug === 'menu'
      );
      const response = await ComboActions.getSubCategoriesForCombo(
        parentCategory?.id,
        1000,
        1
      );
      setCategories(response?.data?.items);
    }
  };

  useEffect(() => {
    fetchRecipeData('');
    fetchParentCategories();
    window.scrollTo(0, 0);
  }, [currentBrand, currentShop]);

  const fileRef = React.useRef<HTMLInputElement>(null);
  let {
    getBase64,
    handleCombo,
    handleDescription,
    handleImage,
    image,
    combo,
    description,
    comboIngredient,
    addComboMenu,
    deleteComboMenu,
    handleChangeValue,
    handleChangePrice,
    handleServing,
    serving,
    totalCost,
    handleChange,
    errorList,
    errorStatus,
    handleChangeCategory,
    comboSizes,
    comboGroups,
    newSize,
    newGroup,
    selectedSizes,
    selectedGroups,
    handleGroupChange,
    handleSizeChange,
    handleNewSize,
    handleNewGroup,
    handleCreateSize,
    handleCreateGroup,
    handleCostIngredient,
    handleTotalPrice,
    update,
  } = props;

  const handleMenu = async (data1: any, index: number) => {
    const recipeList = menu.filter((obj: any) => data1.includes(obj.recipe_id));
    handleChange(recipeList, index);
    // console.log('data recipe', data1);
    if (comboIngredient && quantityValue) {
      handleCostIngredient(comboIngredient, quantityValue, 'quantity', index);
      handleTotalPrice(comboIngredient);
    }
  };

  const handleMenuCategory = async (data1: any, index: number) => {
    const extractedCategory = categories.find(
      (categoryObj: any) => categoryObj?.id === data1
    );
    handleChange([], index); // Comment this line if don't want to remove recipes
    handleChangeCategory(extractedCategory, index);
  };

  const handleQuantity = (data: any, index: number, key: string) => {
    // console.log('data handleQuantity', data, index, key);
    handleChangeValue(data, index, key);
    setQuantityValue(data);
  };

  const handleSalePrice = (data: any, index: number, key: string) => {
    handleChangePrice(data, index, key);
  };

  const handleDropdownVisibleChange = (open: boolean, id: any) => {
    if (open) {
      setMenu([]);
      fetchRecipeData(id);
    }
  };

  // console.log('combo ing', comboIngredient, selectedGroups);
  // console.log('ComboSizes', comboSizes, selectedSizes);
  // useEffect(() => {
  //   if (update) {
  //     setSelectedCategories(comboIngredient);
  //     console.log(
  //       'selectedCategories ing',
  //       comboIngredient,
  //       selectedCategories
  //     );
  //   }
  // }, [update, comboIngredient]);

  return (
    <>
      <div className="step2-head">Combo Information</div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Combo Name <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter combo name"
          size="large"
          value={combo}
          onChange={(e) => handleCombo(e.target.value)}
          required
          className={errorList['combo_name']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['combo_name']}
          </div>
        )}
      </div>

      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">
          Description <span className="sterik">*</span>
        </div>
        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input description here"
          autoSize={{ minRows: 5, maxRows: 6 }}
          value={description}
          onChange={(e) => handleDescription(e.target.value)}
        />
      </div>

      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">Upload Image</div>
        {image ? (
          <div className="imageUpload combo-wizard-image-uploader">
            <div className="imageWrapper">
              <img src={image} alt="" className="imageHeight" />
              <div className="deleteButton" onClick={() => handleImage('', {})}>
                <img src={Delete} width={15} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="formgroup formgroupStepOne combo-wizard-image-uploader">
            <div className="label-wrapper">
              <label>
                <p className="ant-upload-text">
                  Drop your image here or browse
                </p>
                <p className="ant-upload-hint">Minimum file 2 MB</p>
                <input
                  ref={fileRef}
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={async (e: any) => {
                    const response = await getBase64(e.currentTarget.files[0]);
                    if (response) handleImage(response, e);
                  }}
                />
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="step2-head">Combo Detail</div>
      {isFoodicsIntegrated ? (
        <div className="foodicscombocontainer">
          <div className="wizardLabel">
            Size <span className="sterik">*</span>
          </div>
          <Select
            showSearch
            mode="multiple"
            size={'large'}
            onChange={handleSizeChange}
            className={
              errorList['size_name']?.length > 0 ? 'dropdownColorRed' : ''
            }
            style={{ width: 700 }}
            optionFilterProp="children"
            placeholder="Select Size"
            // value={
            //   update
            //     ? selectedSizes?.map((item: any) => item.label) ||
            //       comboSizes?.size_name
            //     : comboSizes?.label
            // }
            // value={comboSizes.map((size: any) => size.id)}
            value={comboSizes?.label}
            options={comboSizes?.map((item: any) => ({
              label: item.size_name,
              value: item.id,
            }))}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder="Create new size"
                    ref={inputRef}
                    value={newSize}
                    onChange={(e) => handleNewSize(e.target.value)}
                    style={{ width: '80%', marginRight: 8 }}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      newSize !== '' && handleCreateSize();
                    }}
                  >
                    Add item
                  </Button>
                </Space>
              </>
            )}
          />
          {selectedSizes.map((size: any) => (
            <div key={size} className="groupContainer">
              <div className="wizardLabel">
                Group for Size{' '}
                {comboSizes?.find((combo: any) => combo.id === size)
                  ?.size_name || comboSizes?.label}
              </div>
              <Select
                showSearch
                mode="multiple"
                size={'large'}
                onChange={(value) => handleGroupChange(size, value)}
                className={
                  errorList['group_name']?.length > 0 ? 'dropdownColorRed' : ''
                }
                style={{ width: 675 }}
                optionFilterProp="children"
                placeholder="Select Group"
                value={
                  update
                    ? comboIngredient?.map((item: any) => item.name)
                    : selectedGroups[size]?.map((group: any) => group.id)
                }
                options={comboGroups?.map((item: any) => ({
                  label: item.group_name,
                  value: item.id,
                }))}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Input
                        placeholder="Create new group"
                        ref={inputRef}
                        value={newGroup}
                        onChange={(e) => handleNewGroup(e.target.value)}
                        style={{ width: '85%', marginRight: 8 }}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          newGroup !== '' && handleCreateGroup();
                        }}
                      >
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
              />
              {selectedGroups[size]?.map((groupId: any) => (
                <div key={groupId} className="menuCategoryContainer">
                  {/* Render Menu Items Based on the Group Selected */}
                  {Object.keys(selectedGroups).map((groupKey) => (
                    <div key={groupKey}>
                      <div className="step2-head combo-wizard-step2-head">
                        Menu Section for{' '}
                        {selectedGroups[size]?.map(
                          (group: any) => group.name
                        ) || ''}
                      </div>
                      <div className="ComboLabelContainer wizardSubContainer">
                        <div className="menuWidthfoodics">
                          Menu <span className="sterik">*</span>
                        </div>
                        <div className="variantWidthfoodics">Recipes</div>
                        <div className="qtyWidthfoodics">
                          Qty <span className="sterik">*</span>
                        </div>
                        {/* <div className="netPriceWidthfoodics">Cost</div> */}
                        <div className="salePriceWidth">Price</div>
                      </div>
                      {comboIngredient.map((data: any, index: number) => {
                        // console.log('comboIngredient ing inn', comboIngredient);
                        return (
                          <div className="inputComboMenuContainer" key={index}>
                            <div
                              className="ComboLabelContainer wizardSubContainer inputFieldContainer"
                              key={index}
                            >
                              <div className="menuWidth recipe-width">
                                <Select
                                  showSearch
                                  size={'large'}
                                  placeholder={'Select Category'}
                                  onChange={(e) => handleMenuCategory(e, index)}
                                  style={{ width: 250 }}
                                  optionFilterProp="children"
                                  value={data?.category?.sub_category}
                                >
                                  {categories?.map(
                                    (category: any, menuIndex: number) => (
                                      <Select.Option
                                        value={category?.id}
                                        key={menuIndex}
                                      >
                                        {category.sub_category}
                                      </Select.Option>
                                    )
                                  )}
                                </Select>
                              </div>
                              <div className="variantWidth recipe-width">
                                <Select
                                  // mode="multiple"
                                  showSearch
                                  size={'large'}
                                  disabled={data?.disable}
                                  placeholder={'Select Recipes'}
                                  onChange={(e) => handleMenu(e, index)}
                                  onDropdownVisibleChange={(open: boolean) =>
                                    handleDropdownVisibleChange(
                                      open,
                                      data?.category?.id
                                    )
                                  }
                                  notFoundContent={
                                    recipeLoader ? (
                                      <Spin size="small" />
                                    ) : (
                                      <div>
                                        No data found for your search query
                                      </div>
                                    )
                                  }
                                  style={{ width: 250 }}
                                  optionFilterProp="children"
                                  value={data?.items?.map(
                                    (ingredient: any) => ingredient?.recipe_id
                                  )}
                                >
                                  {menu?.map((item: any, menuIndex: number) => (
                                    <Select.Option
                                      value={item?.recipe_id}
                                      key={menuIndex}
                                    >
                                      {item?.variation !== ''
                                        ? item?.recipe_name +
                                          ' ' +
                                          `( ${item?.variation} )`
                                        : item?.recipe_name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                              <div className="qtyWidth">
                                <Input
                                  placeholder="0"
                                  size="large"
                                  type="number"
                                  defaultValue={
                                    update
                                      ? data?.category?.quantity
                                      : data.quantity
                                  }
                                  onChange={(e) =>
                                    handleQuantity(
                                      e.target.value,
                                      index,
                                      'quantity'
                                    )
                                  }
                                />
                              </div>
                              {/* <div className="netPriceWidth ComboLabelContainer">
                                <div className="usdLabel">
                                  {brand?.currency_name}
                                </div>
                                <div className="usdValueDiv">{total_price}</div>
                              </div> */}
                              <div className="qtyWidth">
                                <Input
                                  placeholder="0"
                                  size="large"
                                  type="number"
                                  defaultValue={
                                    data.sale_price ||
                                    data?.items?.map(
                                      (recipe: any) => recipe?.sale_price
                                    )
                                  }
                                  onChange={(e) =>
                                    handleSalePrice(
                                      e.target.value,
                                      index,
                                      'sale_price'
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <img
                              src={Delete}
                              width={15}
                              alt=""
                              onClick={() => deleteComboMenu(index)}
                              className="comboMenuDelete foodics-delete"
                            />
                          </div>
                        );
                      })}
                      <div className="addButton" onClick={addComboMenu}>
                        + Add New
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="ComboLabelContainer wizardSubContainer">
            <div className="menuWidth">
              Menu <span className="sterik">*</span>
            </div>
            <div className="variantWidth">Recipes</div>
            <div className="qtyWidth">
              Qty <span className="sterik">*</span>
            </div>
            {/* <div className="netPriceWidth">Cost</div> */}
          </div>
          {comboIngredient.map((data: any, index: number) => {
            // console.log('comboIngredient ing normal', comboIngredient);
            return (
              <div className="inputComboMenuContainer" key={index}>
                <div
                  className="ComboLabelContainer wizardSubContainer inputFieldContainer"
                  key={index}
                >
                  <div className="menuWidth">
                    <Select
                      showSearch
                      size={'large'}
                      placeholder={'Select Category'}
                      onChange={(e) => handleMenuCategory(e, index)}
                      style={{ width: 310 }}
                      optionFilterProp="children"
                      value={data?.category?.sub_category}
                    >
                      {categories?.map((category: any, menuIndex: number) => (
                        <Select.Option value={category?.id} key={menuIndex}>
                          {category.sub_category}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="variantWidth">
                    <Select
                      mode="multiple"
                      showSearch
                      size={'large'}
                      disabled={data?.disable}
                      placeholder={'Select Recipes'}
                      onChange={(e) => handleMenu(e, index)}
                      onDropdownVisibleChange={(open: boolean) =>
                        handleDropdownVisibleChange(open, data?.category?.id)
                      }
                      notFoundContent={
                        recipeLoader ? (
                          <Spin size="small" />
                        ) : (
                          <div>No data found for your search query</div>
                        )
                      }
                      style={{ width: 300 }}
                      optionFilterProp="children"
                      value={data?.items?.map(
                        (ingredient: any) => ingredient?.recipe_id
                      )}
                    >
                      {menu?.map((item: any, menuIndex: number) => (
                        <Select.Option value={item?.recipe_id} key={menuIndex}>
                          {item?.variation !== ''
                            ? item?.recipe_name + ' ' + `( ${item?.variation} )`
                            : item?.recipe_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="qtyWidth">
                    <Input
                      placeholder="0"
                      size="large"
                      type="number"
                      defaultValue={data.quantity}
                      onChange={(e) =>
                        handleQuantity(e.target.value, index, 'quantity')
                      }
                    />
                  </div>
                  {/* <div className="netPriceWidth ComboLabelContainer">
                    <div className="usdLabel">{brand?.currency_name}</div>
                    <div className="usdValueDiv">{data?.total_price}</div>
                  </div> */}
                </div>
                <img
                  src={Delete}
                  width={15}
                  alt=""
                  onClick={() => deleteComboMenu(index)}
                  className="comboMenuDelete"
                />
              </div>
            );
          })}
          <div className="addButton" onClick={addComboMenu}>
            + Add New
          </div>
        </>
      )}
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Serving <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter Serving"
          size="large"
          type="number"
          value={serving}
          onChange={(e) => handleServing(e.target.value)}
          required
          className={errorList['servings']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['servings']}</div>
        )}
      </div>
      <div className="step2-head">Ingredient Cost</div>
      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="comboPriceFieldLabel">Average Cost</div>
            <span
              className={`pricegroup ${
                errorList['total_cost']?.length > 0 ? 'borderRed' : ''
              }`}
            >
              <span>{brand?.currency_name}</span>
              <div>{Number(totalCost?.toFixed(2))}</div>
            </span>
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['total_cost']}
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default ComboStep;
