/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, PaginationProps, Spin, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  DeleteConfirmation,
  InventoryTransferApproval,
  DetailDialog,
} from '../../components';
import {
  CheckCircleOutlined,
  // CheckCircleOutlined,
  CloseCircleOutlined,
  // QuestionCircleOutlined,
} from '@ant-design/icons';
import { sortedData, sortNumber } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import RejectInventoryTransfer from '../../assets/images/rejectInventoryTransfer.png';
import ApproveInventoryTransfer from '../../assets/images/approveInventoryTransfer.png';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SupplierApi from '../../redux/middleware/supplier';
import Utils from '../../redux/utils';
import './inventoryTransfer.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="inventory-transfer-dot-option">Approve</div>,
    icon: <CheckCircleOutlined className="inventory-transfer-iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="inventory-transfer-dot-option">Reject</div>,
    icon: <CloseCircleOutlined className="inventory-transfer-iconDropdown" />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const InventoryTransfer = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [inventoryTransfer, setInventoryTransfer] = useState([{}]);
  // const [database, setDatabase] = useState<any>([]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [selectedDbId] = useState(3);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [rejectDialog, setRejectDialog] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [inventory, setInventory] = useState<any>({});
  const [errorIndex, setErrorIndex] = useState(-1);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');

  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentShop?.shop_id);
  useEffect(() => {
    if (currentBrandId !== currentShop?.shop_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const fetchDetailForApproval = async (id: any) => {
    setLoader(true);
    const getDetail = await SupplierApi.getInventorySupplyById(id);
    setLoader(false);
    if (getDetail?.success) {
      setInventory(getDetail?.data);
      setApprovalModal(true);
    }
  };

  const fetchStatuses = async () => {
    const { data, success } = await SupplierApi.getSupplyStatus('status');
    if (success) {
      setStatuses(data);
    }
  };

  const columns = [
    {
      title: () => (
        <div className="inventory-transfer-table-title inventory-transfer-table-serial-no">
          <span>REQUISITION ID</span>
          <img
            src={UpDownArrow}
            className="inventory-transfer-title-arrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div
          className="inventory-transfer-column inventory-transfer-cell-spacing table-title"
          onClick={() => fetchDialogData(record?.id)}
        >
          {record?.id}
        </div>
      ),
    },
    {
      title: () => (
        <div className="inventory-transfer-table-title">
          <span>RAISED BY BRAND</span>
          <img
            src={UpDownArrow}
            className="inventory-transfer-title-arrow"
            onClick={() => {
              handleSort('brand_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'brand_name',
      key: 'brand_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="inventory-transfer-column inventory-transfer-cell-spacing">
          {record?.brand_name?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tootipText">{record?.brand_name}</div>
                </div>
              }
            >
              {record?.brand_name?.substring(0, 22)}
              {record?.brand_name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          ) : (
            record?.brand_name
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="inventory-transfer-table-title">
          <span>REQUEST TO BRANCH</span>

          <img
            src={UpDownArrow}
            className="inventory-transfer-title-arrow"
            onClick={() => {
              handleSort('shop_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'shop_name',
      key: 'shop_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">
          {record?.shop_name?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tootipText">{record?.shop_name}</div>
                </div>
              }
            >
              {record?.shop_name?.substring(0, 22)}
              {record?.shop_name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          ) : (
            record?.shop_name
          )}
        </div>
      ),
    },

    {
      title: () => (
        <div className="inventory-transfer-table-title">
          <span>STATUS </span>

          <img
            src={UpDownArrow}
            className="inventory-transfer-title-arrow"
            onClick={() => {
              handleSort('request_status', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'request_status',
      key: 'request_status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div
          className={`requestStatusContainer ${
            record?.request_status?.toLowerCase() == 'pending'
              ? 'inventoryTransfer-request-pending'
              : record?.request_status?.toLowerCase() == 'approved'
              ? 'inventoryTransfer-request-approve'
              : 'inventoryTransfer-request-reject'
          } `}
        >
          {record?.request_status}
        </div>
      ),
    },
    {
      title: () => (
        <div className="inventory-transfer-table-title">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="inventory-transfer-title-arrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="inventory-transfer-table-col inventory-transfer-column createdAtAlignment">
            <div className="subrecipecol2">
              {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) =>
        // <Dropdown
        //   menu={{
        //     onClick: handleDropDownClick,
        //     items: items,
        //   }}
        //   trigger={['click']}
        //   placement="bottomRight"
        //   arrow={{ pointAtCenter: true }}
        // >
        //   <a
        //     onClick={() => {
        //       setSelectedMenu(record?.id);
        //     }}
        //   >
        //     <Space>
        //       <div className="inventory-transfer-dots">
        //         <img src={More} />
        //       </div>
        //     </Space>
        //   </a>
        // </Dropdown>
        record?.request_status?.toLowerCase() == 'pending' ? (
          <div className="inventory-transfer-list-icons-container">
            <div
              className="inventory-transfer-detail-button"
              onClick={() => fetchDialogData(record?.id)}
            >
              <img src={Eye} width={20} />
              <div>View</div>
            </div>
            <div className="imagesContainer">
              <Tooltip
                title={
                  <div>
                    <div className="tootipText">Decline</div>
                  </div>
                }
              >
                <img
                  src={RejectInventoryTransfer}
                  alt=""
                  className="inventory-transfer-list-view-icons"
                  onClick={() => {
                    setSelectedMenu(record?.id);
                    setRejectDialog(true);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  <div>
                    <div className="tootipText">Approve</div>
                  </div>
                }
              >
                <img
                  src={ApproveInventoryTransfer}
                  alt=""
                  className="inventory-transfer-list-view-icons"
                  onClick={() => fetchDetailForApproval(record?.id)}
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <div
            className="inventory-transfer-detail-button"
            onClick={() => fetchDialogData(record?.id)}
          >
            <img src={Eye} width={20} />
            <div>View</div>
          </div>
        ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
    fetchStatuses();
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Inventory Transfer'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);

    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentShop, currentBrand]);

  // const fetchDatabase = async () => {
  //   const data = await IngredientActions.getDatabase();
  //   // setDatabase(data?.data);
  // };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   switch (option) {
  //     case 1:
  //       break;
  //     case 2:
  //       // navigate('wizard', { state: { update: true, id: selectedMenu } });
  //       setRejectDialog(true);
  //       break;
  //     default:
  //   }
  // };

  const handleDeleteCancel = () => {
    setRejectDialog(false);
  };
  const handleDeleteContinue = async () => {
    setLoader(true);
    const statusId =
      statuses?.find((status: any) => status?.slug == 'decline')?.id || '';
    const response = await SupplierApi.updateInventorySupplyStatus(
      { request_status_id: statusId, ingredients: [] },
      selectedMenu
    );
    setLoader(false);
    if (response?.success) {
      pageData();
    }
    setRejectDialog(false);
  };

  const fetchDialogData = async (id: any) => {
    const { data, success } = await SupplierApi.getInventorySupplyById(id);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: (
        <div className="inventory-transfer-font inventory-transfer-dot-option">
          {data}
        </div>
      ),
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const pageData = async () => {
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    let request_id, requestTransfer;
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success) {
      request_id = data?.find((supply: any) => supply?.slug == 'requisition');
      requestTransfer = data?.find(
        (supply: any) => supply?.slug == 'inventory_transfer'
      );
    }

    const requisitionData =
      shop?.is_ck == 0
        ? await SupplierApi.searchInventorySupply(
            request_id?.id,
            dataLength,
            current,
            shop?.shop_id ? shop?.shop_id : '',
            brand?.brand_id ? brand?.brand_id : '',
            keyword
          )
        : await SupplierApi.getInventoryTransfer(
            request_id?.id,
            dataLength,
            current,
            shop?.shop_id ? shop?.shop_id : '',
            requestTransfer?.id,
            brand?.brand_id ? brand?.brand_id : ''
          );

    if (requisitionData === undefined || !requisitionData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(requisitionData?.data?.supply_count);
      setTotal(requisitionData?.data?.pagination?.total);
      setInventoryTransfer(requisitionData?.data?.items);
      setSortData(requisitionData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    // const shop = await Utils.getCurrentShop();
    // const brand = await Utils.getCurrentBrand();
    // setSearch(keyword);
    setRecipeLoader(true);
    // let request_id, requestTransfer;
    // const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    // if (success) {
    //   request_id = data?.find((supply: any) => supply?.slug == 'requisition');
    //   requestTransfer = data?.find(
    //     (supply: any) => supply?.slug == 'inventory_transfer'
    //   );
    // }
    // const searchResultofVariations = await SupplierApi.searchInventoryTransfer(
    //   request_id?.id,
    //   dataLength,
    //   current,
    //   shop?.shop_id ? shop?.shop_id : '',
    //   requestTransfer?.id,
    //   brand?.brand_id ? brand?.brand_id : '',
    //   keyword
    // );
    // if (
    //   searchResultofVariations === undefined ||
    //   !searchResultofVariations?.success
    // ) {
    //   setRecipeCount(0);
    //   setTotal(0);
    //   setSortData([]);
    // } else {
    //   setRecipeCount(searchResultofVariations?.data?.supply_count);
    //   setTotal(searchResultofVariations?.data?.pagination?.total);
    //   setSortData(searchResultofVariations?.data?.items);
    // }

    if (keyword) {
      const results = sortData.filter(
        (obj: any) =>
          obj.brand_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.shop_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.request_status?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.created_at?.includes(keyword)
      );
      setSortData(results);
    } else {
      setSortData(inventoryTransfer);
    }

    setRecipeLoader(false);
  };

  const handleInventory = (ingredients: any[]) => {
    setErrorIndex(-1);
    setInventory({ ...inventory, ingredients });
  };

  const SubmitApproval = async () => {
    const emptyQuantityIndex = inventory?.ingredients?.findIndex(
      (data: any) =>
        data?.transfer_quantity == null || data?.transfer_quantity == 0
    );
    if (emptyQuantityIndex != -1) {
      setErrorIndex(emptyQuantityIndex);
      return;
    } else {
      const statusId =
        statuses?.find((status: any) => status?.slug == 'approved')?.id || '';
      const params = {
        request_status_id: statusId,
        ingredients: inventory?.ingredients?.map((data: any) => ({
          ingredient_id: data?.ingredient_id,
          request_quantity: data?.request_quantity,
          transfer_quantity: data?.transfer_quantity,
        })),
      };

      const { success } = await SupplierApi.updateInventorySupplyStatus(
        params,
        inventory?.id
      );
      if (success) {
        setApprovalModal(false);
        pageData();
        // let responseDialogData = {
        //   OnClick: () => {
        //     setApprovalModal(false);
        //     pageData();
        //   },
        //   type: 'success',
        //   messageArray: [` Your Requisition`, ` has been Approved`],
        // };
        // ResponseDialog(responseDialogData);
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Inventory Transfer"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={null}
        // perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Inventory Transfer">
          <DetailDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
            component={'requisition'}
          />
          <Row className="inventory-transfer-listingTopFilter">
            <Col lg={10} md={24}>
              <div style={{ display: 'flex', marginRight: '100px' }}>
                <ListCount
                  count1={recipeCount}
                  title1="Total Inventory Transfer"
                  title3="Brand"
                  title4="Branch"
                  component={'Inventory Transfer'}
                />
                {/* <div className="uploadImageMenuContainer">
                {database?.map((db: any, index: any) => {
                  return (
                    <>
                      <div
                        className={
                          selectedDbId === db?.id ? 'menu' : 'menuLight'
                        }
                      >
                        <div
                          onClick={() => handleActiveTab(db)}
                          className="text menustyle"
                        >
                          {db?.db_name}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div> */}
              </div>
            </Col>
            <Col lg={14} md={24}>
              <div className="inventory-transfer-search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText="Search..."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <DeleteConfirmation
              handleDeleteCancel={handleDeleteCancel}
              handleDeleteContinue={handleDeleteContinue}
              open={rejectDialog}
              message={`Are you sure want to reject requisition?`}
            />
            <InventoryTransferApproval
              approvalModal={approvalModal}
              handleCancel={() => setApprovalModal(false)}
              inventory={inventory}
              handleInventory={handleInventory}
              SubmitApproval={SubmitApproval}
              errorIndex={errorIndex}
            />
            <Col lg={24} md={24}>
              {!recipeLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={items}
                  dataSource={[...sortData] || []}
                  columns={columns}
                />
              ) : (
                <div className="inventory-transfer-loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default InventoryTransfer;
