/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Tooltip,
  Spin,
  Select,
  Dropdown,
  Space,
} from 'antd';
import type { MenuProps } from 'antd';
import Utils from '../../redux/utils';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Breadcrumb,
  Dialog,
  Button,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  MenuBar,
  DonutChart,
  SyncPreferences,
  ResponseDialog,
  MenuModal,
} from '../../components';
import MenuActions from '../../redux/middleware/menu';
import ComboActions from '../../redux/middleware/combo';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import { handleFloat, sortNumber, sortedData } from '../../helper/sortData';
import Export from '../../assets/images/export.png';
import Fire from '../../assets/images/Fire.svg';
import Download from '../../assets/images/Download.svg';
import Clock from '../../assets/images/Clock.png';
import Serving from '../../assets/images/Serving.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import Edit from '../../assets/images/Edit.svg';
import FireWhite from '../../assets/images/FireWhite.png';
import Peanuts from '../../assets/images/peanuts.png';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import { useSelector } from 'react-redux';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import './menu.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';

const items: MenuProps['items'] = [
  {
    key: '2',
    label: <div className="dotOption">Download</div>,
    icon: <img src={Download} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
];

const PageDataLength = [6, 10, 20, 50];

const MenuPage = () => {
  const user = Utils.getCurrentUser();
  const { t } = useTranslation('translation', {
    keyPrefix: 'menuPage',
  });
  const brand = Utils.getCurrentBrand();
  const [loader, setLoader] = useState(false);
  const [design, setDesign] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [menu, setMenu] = useState([{}]);
  const [modalData, setModalData] = useState({});
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [recipeTypes, setRecipeTypes] = useState('');
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [menuStatus, setMenuStatus] = useState('ALL');
  const [popUp, setPopUp] = useState<any>(null);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId, setCurrentBrandId] = useState(currentBrand?.brand_id);
  const [openDialogForSync, setOpenDialogForSync] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [processId, setProcessId] = useState<any>(null);
  const userRole = Utils.getUserRole()?.id;
  const scrollPosition = React.useRef(0);
  const toggleRef = React.useRef(null as any);
  toggleRef.current = false;

  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const setSelectedMenu = (recipe: any) => {
    setSelectedRecipeId(recipe.rb_id);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const handleDialog = (checked: boolean, data: any) => {
    setOpen(checked);
    setModalData(data);
  };

  const handlemodal = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    pageData();
    setOpen(false);
    scrollPosition.current = window.scrollY;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>MENU TITLE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe_name',
      key: 'recipe_name',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col1 font">
            <img
              className="listView-image-align"
              src={record?.thumbnail}
              width="52px"
              height="52px"
              onClick={() => {
                handleingredient(record);
              }}
            />
            <div>
              <div className="subCol1 titleContainer font">
                <span
                  className="table-title1-hover cursor"
                  onClick={() => {
                    handleDialog(true, record);
                  }}
                  // onClick={() => {
                  //   setDialogData(record);
                  //   setOpenDialog(true);
                  // }}
                >
                  {record?.menu_category === 'Combo' ? (
                    record?.combo_name?.length > 22 ? (
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipText">
                              {record?.combo_name || record?.recipe_name}
                            </div>
                          </div>
                        }
                      >
                        {record?.combo_name?.substring(0, 22) ||
                          record?.recipe_name?.substring(0, 22)}
                        {record?.combo_name?.length > 22 ? (
                          <span>...</span>
                        ) : null}
                      </Tooltip>
                    ) : (
                      record?.combo_name || record?.recipe_name
                    )
                  ) : record?.recipe_name?.length > 22 ? (
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipText">
                            {record?.recipe_name}
                          </div>
                        </div>
                      }
                    >
                      {record?.recipe_name?.substring(0, 22)}
                      {record?.recipe_name?.length > 22 ? (
                        <span>...</span>
                      ) : null}
                    </Tooltip>
                  ) : (
                    record?.recipe_name
                  )}
                </span>
                <span>
                  <div className="categoryPill">
                    <div className="pillText">{record?.menu_category}</div>
                  </div>
                </span>
              </div>
              <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-gap">
                <div className="common-flex-center IconsAlignment">
                  {' '}
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={record}
                    component={'menu'}
                  >
                    {record.is_verified ? (
                      <img
                        src={FireWhite}
                        className="subCol1Icon iconFireWhite"
                      />
                    ) : (
                      <img src={Fire} width={15} className="subCol1Icon" />
                    )}
                  </DonutChart>
                  {!record?.calorie && record?.is_variation == 'yes'
                    ? handleFloat(record?.variation_list[0]?.calorie)
                    : handleFloat(record?.calorie)}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Servings</div>
                        <div className="tootipText">
                          {record?.servings} person serving
                        </div>
                      </div>
                    }
                  >
                    <img src={Serving} alt="" className="icons" />
                  </Tooltip>
                  {record?.servings}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Sub recipe</div>
                        <div className="tootipText">
                          Total {record?.sub_recipe_count} sub recipes
                        </div>
                      </div>
                    }
                  >
                    <img src={SubMenu} width={15} alt="" className="icons" />
                  </Tooltip>
                  {record?.sub_recipe_count}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        {moment(UTCtoLocal(record?.created_at)).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Clock} alt="" />
                  </Tooltip>
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Allergens Present:</div>
                        <div className="tootipText">
                          {record?.allergen || ''}
                        </div>
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Peanuts} alt="" className="icons" width={20} />
                    {record?.allergen?.split(',')?.length || 0}
                  </Tooltip>
                </div>
                {record?.variation_list?.length !== 0 ? (
                  <Select
                    onChange={(e) => {
                      handleChange(e, index, record?.variation_list);
                    }}
                    defaultValue={record?.variation_list?.[0].variation}
                    style={{}}
                    bordered={false}
                  >
                    {record?.variation_list?.map(
                      (data: any, innerindex: number) => (
                        <Select.Option
                          value={data?.variation_id}
                          key={innerindex}
                        >
                          {data.variation}
                        </Select.Option>
                      )
                    )}
                  </Select>
                ) : (
                  ''
                )}
              </div>
              {/* <div className="subCol1 textContainer">
                {record?.description?.substring(0, 20)}...
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">Description</div>
                      <div className="tootipText">{record?.description}</div>
                    </div>
                  }
                >
                  <span className="readMoreBtn">read more</span>
                </Tooltip>
              </div> */}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>VARIATION</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'category',
    //   key: 'category',
    //   responsive: ['md'],
    //   render: (data: any, record: any, index: number) =>
    //     record?.variation_list?.length !== 0 ? (
    //       <div className="col3 cellSpacing">
    //         <Select
    //           onChange={(e) => {
    //             handleChange(e, index, record?.variation_list);
    //           }}
    //           defaultValue={record?.variation_list?.[0].variation}
    //           style={{ width: 120, marginTop: -5 }}
    //           bordered={false}
    //         >
    //           {record?.variation_list?.map((data: any, innerindex: number) => (
    //             <Select.Option value={data?.variation_id} key={innerindex}>
    //               {data.variation}
    //             </Select.Option>
    //           ))}
    //         </Select>
    //       </div>
    //     ) : (
    //       ''
    //     ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUGGESTED PRICE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('propose_price', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'propose_price',
      key: 'propose_price',
      responsive: ['md'],
      render: (data: any, record: any) => {
        // console.log(record);
        const { total_cost, propose_price } = record;
        let tempSuggestedPrice = total_cost / (brand?.cost_ratio / 100);
        let suggested = propose_price > 0 ? propose_price : tempSuggestedPrice;
        return (
          <Tooltip
            title={
              <div>
                {/* <div className="tooltipHead">
                            Profitability %:
                          </div> */}
                <div className="tootipText">
                  Suggested Price : {handleFloat(suggested)}
                </div>
              </div>
            }
          >
            <div className="col3">{handleFloat(suggested)}</div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span> SELLING PRICE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('final_price', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'final_price',
      key: 'final_price',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col3 actual-price-color-list">
            {/* {brand?.currency_name + ' ' + record?.final_price} */}

            <Tooltip
              title={
                <div>
                  {/* <div className="tooltipHead">
                                  Profitability %:
                                </div> */}
                  <div className="tootipText">
                    Selling Price : {handleFloat(record?.final_price)}
                  </div>
                </div>
              }
            >
              <div className="rupeesTag cursor selling-price">
                {brand?.currency_name} {handleFloat(record?.final_price)}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PROFITABILITY %</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('profitability', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'profitability',
      key: 'profitability',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Tooltip
            title={
              <div>
                {/* <div className="tooltipHead">
                            Profitability %:
                          </div> */}
                <div className="tootipText">
                  Profitability % : {record?.profitability}
                </div>
              </div>
            }
          >
            <div className="col3">{record?.profitability}%</div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={menuDropdownProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedRecipeId(record?.id);
              console.log('record', record?.item_type);
              setRecipeTypes(record?.item_type);
            }}
          >
            <Space>
              <div
                className={
                  record?.status === 1
                    ? 'statusContainerActive'
                    : 'statusContainerNonActive'
                }
              >
                <div className="status">
                  {record?.status == 1 ? 'Active' : 'Non-active'}
                </div>
                <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  // useEffect(() => {
  //   setCurrent(1);
  // }, []);

  useEffect(() => {
    checkAuthentication(user, navigate);
    if (dataLength && current) {
      setCurrentBrandId(currentBrand?.brand_id);
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={async () => {
            const statusChangeResponse = await MenuActions.changeStatusMenu(
              selectedRecipeId,
              1,
              recipeTypes
            );
            const { success, error } = statusChangeResponse;
            if (success) {
              let filterData = sortData;
              setSortData(filterData);
              toggleRef.current = false;
              setProcessId(null);
              pageData();
            } else {
              toggleRef.current = false;
              setProcessId(null);
              const { id = 'Something went wrong, Please try again' } = error;
              const responseDialogData = {
                OnClick: () => {},
                type: 'error',
                messageArray: ['', `${id}`],
              };
              ResponseDialog(responseDialogData);
            }
          }}
        >
          {'Active'}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={async () => {
            const statusChangeResponse = await MenuActions.changeStatusMenu(
              selectedRecipeId,
              0,
              recipeTypes
            );
            const { success, error } = statusChangeResponse;
            if (success) {
              let filterData = sortData;
              setSortData(filterData);
              toggleRef.current = false;
              setProcessId(null);
              pageData();
            } else {
              toggleRef.current = false;
              setProcessId(null);
              const { id = 'Something went wrong, Please try again' } = error;
              const responseDialogData = {
                OnClick: () => {},
                type: 'error',
                messageArray: ['', `${id}`],
              };
              ResponseDialog(responseDialogData);
            }
          }}
        >
          {'Not Active'}{' '}
        </div>
      ),
    },
  ];

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    // setDataLength(PageDataLength[+e?.key]);
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const brand = await Utils.getCurrentBrand();
    const details = await MenuActions.getMenuDetail(
      data?.id,
      currentBrand?.brand_id || brand?.brand_id,
      data?.item_type
    );
    setDialogData(details?.data?.items[0]);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', { state: { update: true, id: selectedRecipeId } });
        break;

      case 4:
        break;
      default:
    }
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCookBook = async () => {
    setLoader(true);
    const response = await RecipeApiActions.exportBrandBookMenu();
    const { success, data } = response;
    if (success) {
      openInNewTab(data);
    }
    setLoader(false);
  };

  const getFilteredMenuItems = async (status: any) => {
    const menuData = await MenuActions.getActiveInactiveMenu(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      keyword,
      status
    );
    if (menuData === undefined || !menuData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      const { items } = menuData?.data || null;
      if (items?.length > 0) {
        setRecipeCount(menuData?.data?.menu_count);
        setIngredientCount(menuData?.data?.ingredients_count);
        setTotal(menuData?.data?.pagination?.total);
        setMenu(menuData?.data?.items);
        setSortData(menuData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const menuData = await MenuActions.getManageMenu(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      keyword
    );
    if (menuData === undefined || !menuData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      const { items } = menuData?.data || null;
      if (items?.length > 0) {
        // const filterList = items.filter((x: any) => x.item_type != 'combo');
        setMenuStatus('ALL');
        setRecipeCount(menuData?.data?.menu_count);
        setIngredientCount(menuData?.data?.ingredients_count);
        setTotal(menuData?.data?.pagination?.total);
        setMenu(menuData?.data?.items);
        setSortData(menuData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const recipeData = await MenuActions.menuFilters(
      dataLength,
      current,
      filters,
      search
    );
    if (recipeData === undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.menu_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleMenuFilter = async (filter: any, name: any) => {
    setRecipeLoader(true);
    if (name === 'Combo') {
      const comboData = await ComboActions.comboFilter(dataLength, filter);
      if (comboData === undefined || !comboData?.success) {
        setRecipeCount(0);
        setTotal(0);
        setSortData([]);
      } else {
        setRecipeCount(comboData?.data?.combo_count);
        setTotal(comboData?.data?.pagination?.total);
        setSortData(comboData?.data?.items);
      }
    } else {
      const recipeData = await MenuActions.menuPillFilter(
        dataLength,
        current,
        filter
      );
      if (recipeData === undefined || !recipeData?.success) {
        setRecipeCount(0);
        setTotal(0);
        setSortData([]);
      } else {
        setRecipeCount(recipeData?.data?.menu_count);
        setTotal(recipeData?.data?.pagination?.total);
        setSortData(recipeData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.recipe_name?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.calorie?.toString().includes(keyword.toString()) ||
    //       obj.propose_price?.toString()?.includes(keyword.toString()) ||
    //       obj.servings?.includes(keyword) ||
    //       obj.sub_recipe_count?.includes(keyword) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(menu);
    // }

    const searchResultofRecipes = await MenuActions.searchMenu(
      dataLength,
      current,
      keyword
    );
    const { success } = searchResultofRecipes;
    if (!success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.menu_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      const { items } = searchResultofRecipes?.data || null;
      if (items?.length > 0 && keyword === '') {
        const filterList = items.filter((x: any) => x.item_type != 'combo');
        setSortData(filterList || searchResultofRecipes?.data?.items);
      } else {
        setSortData(searchResultofRecipes?.data?.items);
      }
    }
    setRecipeLoader(false);
  };
  const handleChange = (data: any, index: number, list: any) => {
    const selectedVariation: any = list.find((val: any) => {
      return val.variation_id === data;
    });
    handledata(selectedVariation, index);
  };
  const handledata = (data: any, index: number) => {
    let items: any = sortData;
    items[index].servings = data[0]?.servings;
    items[index].created_at = data[0]?.created_at;
    items[index].final_price = data[0]?.final_price;
    items[index].calorie = data[0]?.calorie;
    items[index].carb = data[0]?.carb;
    items[index].fat = data[0]?.fat;
    items[index].protein = data[0]?.protein;
    setSortData([...items]);
  };

  const actionButton = () => {
    const { integrations } = Utils.getCurrentBrand();
    const { id } = Utils.getUserRole();
    const isFoodicsIntegrated = integrations.length > 0;
    // TODO: Add this for Live Build
    // if (id === 3) return null;
    return (
      <Button
        text="Sync Preferences"
        className="theme-button recipeBtn"
        handleButtonClick={() => {
          setOpenDialogForSync(true);
        }}
        shape="round"
        size="middle"
        disabled={!isFoodicsIntegrated}
        title={
          !isFoodicsIntegrated
            ? 'Your current Brand does not have Foodics Integration'
            : ''
        }
      />
    );
  };

  return (
    <div className="menuPage">
      {open ? (
        <MenuModal
          handleDialog={handlemodal}
          open={open}
          modalData={modalData}
          onSubmit={onSubmit}
        />
      ) : null}
      {openDialogForSync ? (
        <SyncPreferences
          open={openDialogForSync}
          handleCancel={() => setOpenDialogForSync(false)}
          component="Sync-Preferences"
        />
      ) : null}
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={() => {
          setOpenDialog(false);
          setOpenSubDialog(true);
        }}
        data={dialogData}
        component={'menu'}
      />
      <SubDialog
        open={openSubDialog}
        data={dialogData}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <Breadcrumb
        heading="Menu"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={userRole === 3 ? actionButton() : null}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={2} md={24}>
            <ListCount
              count1={recipeCount}
              title1="Total Menu"
              // count2={ingrdientCount}
              // title2="Total Ingredients"
            />
          </Col>
          <Col lg={8} md={24} className="brand-filter-container">
            <Select
              size="large"
              placeholder="Menu Status"
              value={menuStatus}
              style={{ width: 160 }}
              onChange={(value) => {
                setMenuStatus(value);
                if (value === 'ALL') {
                  pageData();
                } else if (value === '1') {
                  getFilteredMenuItems(1);
                } else if (value === '0') {
                  getFilteredMenuItems(0);
                }
              }}
            >
              <Select.Option key="ALL" value="ALL">
                ALL Items
              </Select.Option>
              <Select.Option key="1" value="1">
                Active
              </Select.Option>
              <Select.Option key="0" value="0">
                Non Active
              </Select.Option>
            </Select>
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
              </div>
              <div className="exportButtonForCookBook" onClick={handleCookBook}>
                <img src={Export} alt="" />
                <div className="exportText"> Export Brand Book</div>
              </div>
              {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export</div>
              </div> */}
              <IconSwitcher
                component="menu"
                listViewLayout={design}
                setListView={setDesign}
              />
            </div>
          </Col>
        </Row>

        <Row>
          {/* {!design ? (
            <Col lg={6} md={8}>
              <Filter
                action={pageDataFilter}
                apiCall={MenuActions.getMenuFilters}
                component={'menu'}
              />
            </Col>
          ) : null} */}

          <Col lg={24} md={18}>
            <MenuBar
              component="menu"
              handleSelectedPill={handleMenuFilter}
              allowMorePills={design ? true : false}
              pillRender={currentBrandId}
            />

            {!design && !recipeLoader ? (
              <GridView
                handledata={handledata}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'menu'}
                handleDialog={handleDialog}
                UTCtoLocal={UTCtoLocal}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}

            {design && !recipeLoader ? (
              <ListView
                limit={dataLength}
                items={items}
                dataSource={[...sortData]}
                columns={columns}
                component={'menu'}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default MenuPage;
