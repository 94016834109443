/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  Select,
  Input,
  SelectProps,
  Checkbox,
  Divider,
  Space,
  Button,
  Dropdown,
} from 'antd';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import {
  Confirmation,
  IngredientDialog,
  SubRecipeDropdown,
  SubWizardDropdown,
} from '../index';
import IngredientActions from '../../redux/middleware/ingredient';
import ArrowDown from '../../assets/images/ArrowDown.png';
import { PlusOutlined } from '@ant-design/icons';
import Utils from '../../redux/utils';
import { handleFloat, handleFloatForSpecialCase } from '../../helper/sortData';
import type { InputRef, MenuProps } from 'antd';
import './styles.scss';

type Props = {
  SubRecipeName: any;
  category: any;
  unit: any;
  yeild: any;
  costRatio: any;
  totalPrice: any;
  price: any;
  waste: any;
  finalPrice: any;
  ingredient: any;
  handleRecipe: any;
  handleUnit: any;
  handleYeild: any;
  handleCostRatio: any;
  handleFinalPrice: any;
  handleWaste: any;
  handleIngredient: any;
  deleteIngredient: any;
  addIngredient: any;
  component: String;
  handleIngredientObject: any;
  screenName: String;
  handleChangeDatabase: any;
  sustainability: any;
  onChangeMenu: any;
  menu: any;
  database: any;
  errorStatus?: boolean;
  errorList?: any;
  handleSubCategory?: any;
  subCategory?: any;
  // parentSubCategory?: any;
  toggle: any;
  blocked?: any;
  hide?: any;
  productionCategoryList?: any;
  handleProductionCategory?: any;
  productionCategory?: any;
  handleSustainability?: any;
  handleEmptyIngredient?: any;
  ingredientCategoryList?: any;
  reuseWastePercent?: any;
  compostableWastePercent?: any;
  handleWastePercent?: any;
  reuseWasteValue?: any;
  compostableWasteValue?: any;
  ingredientCategory?: any;
  handleIngredientCategory?: any;
  ingredientUnit?: any;
  handleIngredientUnit?: any;
  ingredientLabelName?: any;
  handleIngredientName?: any;
  onSubmitKitchenSection?: any;
  update?: boolean;
};
const SustainabilityStepOne = (props: Props) => {
  const brand = Utils.getCurrentBrand();
  const [units, setUnits] = useState<any[]>();
  const [ingredientRemove, setIngredientRemove] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState(-1); // For manage ingredient index to remove ingredient_name, when we create ingredient from ingredient dropdown
  const [kitchenSection, setKitchenSection] = useState('');
  const inputRef = useRef<InputRef>(null);
  // eslint-disable-next-line no-unused-vars
  // const [option, setOption] = useState<SelectProps['options']>([
  //   { value: '', label: '', key: '' },
  // ]);
  const [ingredientName, setIngredientName] = useState();

  let {
    SubRecipeName,
    // category,
    yeild,
    costRatio,
    totalPrice,
    price,
    waste,
    unit,
    finalPrice,
    ingredient,
    handleRecipe,
    handleUnit,
    handleYeild,
    handleCostRatio,
    handleFinalPrice,
    handleWaste,
    handleIngredient,
    deleteIngredient,
    addIngredient,
    component,
    handleIngredientObject,
    screenName,
    handleChangeDatabase,
    database,
    errorList,
    errorStatus,
    handleSubCategory,
    subCategory,
    onChangeMenu,
    toggle,
    menu,
    update,
    // parentSubCategory,
    ingredientCategoryList,
    sustainability,
    handleSustainability,
    handleProductionCategory,
    productionCategory,
    productionCategoryList,
    handleEmptyIngredient,
    blocked,
    hide,
    reuseWastePercent,
    compostableWastePercent,
    handleWastePercent,
    reuseWasteValue,
    compostableWasteValue,
    ingredientCategory,
    handleIngredientCategory,
    ingredientUnit,
    handleIngredientUnit,
    ingredientLabelName,
    handleIngredientName,
    onSubmitKitchenSection,
  } = props;
  const items: MenuProps['items'] = units?.map((data: any) => {
    return {
      key: `${data?.id}`,
      label: <div className="dropdownOptions">{data?.unit}</div>,
    };
  });
  const [openIngredientDialog, setOpenIngredientDialog] = useState(false);
  const fetchUnits = async () => {
    const data = await SubRecipeActions.getSubRecipeUnits();
    setUnits(data?.data);
    if (data?.data) {
      handleUnit([data?.data[0]]);
    }
  };

  // const fetchDatabase = async () => {
  //   const data = await IngredientActions.getDatabase();

  //   const options: SelectProps['options'] = data?.data?.map((country: any) => {
  //     return {
  //       value: country?.db_name,
  //       label: country?.db_name,
  //       key: country?.id,
  //     };
  //   });

  //   setOption(options);
  // };

  useEffect(() => {
    // fetchDatabase();
    if (!units?.length) fetchUnits();
    window.scrollTo(0, 0);
  }, []);

  const handleIngredientOnChange = (data: any, index: any, key: string) => {
    handleIngredient(data, index, key);
    key == 'ingredient_name' && setIngredientName(data);
  };

  const handleDialog = () => {
    handleIngredientOnChange('', ingredientIndex, 'ingredient_name');
    handleChangeDatabase(database?.value, database);
    setOpenIngredientDialog(false);
  };

  const handleContinueDeleteIngredients = () => {
    setIngredientRemove(false);
    handleEmptyIngredient();
    handleSustainability(!sustainability);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    const unitObject = units?.filter((data: any) => data?.id == option);
    console.log(unitObject);

    handleUnit(unitObject);
    // handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  return (
    <React.Fragment>
      {ingredientRemove && (
        <Confirmation
          handleCancel={() => {
            setIngredientRemove(false);
          }}
          handleContinue={handleContinueDeleteIngredients}
          open={ingredientRemove}
          message={`Your selected ingredients will be removed\nDo you want to continue?`}
        />
      )}
      {openIngredientDialog ? (
        <IngredientDialog
          handleDialog={handleDialog}
          open={openIngredientDialog}
          component={'sub recipe'}
          name={ingredientName}
        />
      ) : null}
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          {component == 'productionRecipe'
            ? 'Production Recipe Name'
            : 'Sub Recipe Name'}{' '}
          <span className="sterik">*</span>
        </div>
        <Input
          placeholder={`Enter ${
            component == 'productionRecipe'
              ? 'Production Recipe Name'
              : 'Sub Recipe Name'
          }`}
          size="large"
          value={SubRecipeName}
          disabled={blocked}
          onChange={(e) => {
            handleRecipe(e.target.value);
          }}
          required
          className={errorList['recipe_name']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_name']}
          </div>
        )}
      </div>
      {/* <div className="wizardSubContainer">
        <span className="category">
          <div className="wizardLabel">
            Category <span className="sterik">*</span>
          </div>

          <span>{category?.name}</span>
        </span>
      </div> */}

      {/* <div className="wizardSubContainer checkBox">
        {types?.map((data: any, index: number) => {
          return (
            <Checkbox
              onChange={(e) => onChangeMenu(e, data)}
              value={menu?.status}
              checked={data?.parent_category == menu?.parent_category}
              key={index}
            >
              {data?.parent_category}
            </Checkbox>
          );
        })}
        {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )}
      </div>  */}

      <div className="wizardDropdown">
        <div className="wizardLabel">
          Sub Recipe Category <span className="sterik">*</span>
        </div>
        {/* <Select
          size={'large'}
          placeholder={'Select Sub Category'}
          onChange={handleSubCategory}
          style={{ width: 560 }}
          value={subCategory}
          options={parentSubCategory}
        /> */}
        <SubWizardDropdown
          component={component == 'subRecipe' ? 'sub_recipe' : 'production'}
          text={subCategory?.sub_category}
          handleChangeText={handleSubCategory}
          menu={menu}
          toggle={toggle}
          blocked={hide}
        />
      </div>

      <div className="wizardDropdown">
        <div className="wizardLabel">Kitchen Section</div>
        <Select
          size={'large'}
          onChange={(e) => {
            let extractObj = productionCategoryList?.find(
              (object: any) => object?.id === e
            );

            handleProductionCategory(extractObj);
          }}
          className={
            errorList['sub_category']?.length > 0 ? 'dropdownColorRed' : ''
          }
          style={{ width: '100%' }}
          optionFilterProp="children"
          placeholder="Select Kitchen Section"
          value={productionCategory?.label}
          options={productionCategoryList?.map((item: any) => ({
            label: item.sub_category,
            value: item.id,
          }))}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Input
                  placeholder="Please enter item"
                  ref={inputRef}
                  value={kitchenSection}
                  onChange={(e) => setKitchenSection(e.target.value)}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    kitchenSection !== '' &&
                      onSubmitKitchenSection(kitchenSection);
                    console.log('kitchenSection', kitchenSection);
                    setKitchenSection('');
                  }}
                >
                  Add item
                </Button>
              </Space>
              {errorStatus && (
                <div className="subRecipeErrorMessages error-cat">
                  {errorList['sub_category']}
                </div>
              )}
            </>
          )}
        />
        {/* {productionCategoryList?.map((data: any, index: number) => (
            <Select.Option value={data?.id} key={index}>
              {data?.sub_category}
            </Select.Option>
          ))}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={kitchenSection}
              onChange={(e) => setKitchenSection(e.target.value)}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={() =>
                kitchenSection !== '' && onSubmitKitchenSection(kitchenSection)
              }
            >
              Add item
            </Button>
          </Space>
        </Select> */}
      </div>

      <div className="subRecipeIngredient-head">Ingredients Information</div>
      {/* <div className="wizardDropdown">
        <div className="wizardLabel">
          Select Database <span className="sterik">*</span>
        </div>
        <Select
          size={'large'}
          defaultValue={database?.label}
          onChange={(e: any, data: any) => {
            handleChangeDatabase(e, data);
          }}
          style={{ width: 560 }}
          options={option}
        />
        </div> */}
      <div className="wizardSubContainer" style={{ marginTop: '20px' }}>
        <div className="subrecipe-calorie-ingredient-container">
          <div className="wizardIngredientsLabel">
            <span className="sustainabilityIngredientsLabel">
              <span className="wizardLabel">
                Add Ingredients <span className="sterik">*</span>
              </span>
            </span>

            <span className="label">
              <span className="wizardLabel">
                Qty <span className="sterik">*</span>
              </span>
            </span>

            <span className="label">
              <span className="wizardLabel">
                Unit <span className="sterik">*</span>
              </span>
            </span>

            {/* <span className="label">
              <span className="wizardLabel">Waste %</span>
            </span> */}

            <span className="label">
              <span className="wizardLabel">
                Cost<span className="sterik">*</span>
              </span>
            </span>
          </div>
          <div className="subrecipe-calorie-ingredient-container labels-sustainable-container">
            <div className="sub-recipe-additional-calorie-label">Calories</div>
            <div className="sub-recipe-additional-calorie-label">
              {/* Total Cost */}
            </div>
          </div>
        </div>
        {ingredient.map((data: any, index: number) => {
          return (
            <SubRecipeDropdown
              key={index}
              data={data}
              index={index}
              database={database}
              blocked={blocked}
              handleIngredientObject={handleIngredientObject}
              openDialog={() => {
                setOpenIngredientDialog(true);
                setIngredientIndex(index);
              }}
              handleIngredientOnChange={handleIngredientOnChange}
              deleteIngredient={deleteIngredient}
              component={'sustainability'}
            />
          );
        })}
        {/* {errorStatus && (
          <div
            className="subRecipeErrorMessages"
            style={{ margin: ' 4px 5px 5px 85px' }}
          >
            {errorList['recipe_name']}
          </div>
        )} */}
      </div>

      {!sustainability ? (
        <div className="addButton">
          <div
            className="addNewsubDiv"
            onClick={() => {
              addIngredient();
            }}
          >
            + Add New Row
          </div>
        </div>
      ) : null}
      {/* <div className="row"> */}
      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel sustainable-yield-unit-label">
              Yield <span className="sterik">*</span>
            </div>

            <div className="sustainable-gramContainer">
              <div className="sustainable-gramSubContainer">
                <Input
                  placeholder="Enter yield"
                  size="large"
                  type="text"
                  value={handleFloat(yeild)}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '') {
                      handleYeild('');
                    } else {
                      const floatValue = parseFloat(value);
                      if (!isNaN(floatValue)) {
                        handleYeild(floatValue);
                      }
                    }
                  }}
                  required
                />

                <div className="sustainable-gramBoxWrapper">
                  <div className="gramBox">
                    <Dropdown
                      menu={DropDownmenuProps}
                      trigger={['click']}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <a>
                        <Space>
                          <div>{unit?.unit}</div> <img src={ArrowDown} alt="" />
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span className="col">
            <div className="wizardLabel">Total Cost</div>
            <span className="pricegroup">
              <span>{brand?.currency_name}</span>
              <div>{handleFloat(totalPrice)}</div>
            </span>
          </span>
        </div>
      </div>

      {/* </div> */}
      {/* <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel">
              Unit <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              onChange={(e) => {
                let extractObj =
                  screenName === 'Sub Recipe'
                    ? units?.filter((object: any) => object?.id === e)
                    : units?.filter(
                        (object: any) => object?.storage_unit_id === e
                      );

                handleUnit(extractObj);
              }}
              className={
                errorList['unit_id']?.length > 0 ? 'dropdownUnitColorRed' : ''
              }
              style={{ width: '100%' }}
              optionFilterProp="children"
              placeholder="Select Unit"
              value={
                screenName === 'Sub Recipe' ? unit?.unit : unit?.storage_unit
              }
            >
              {units?.map((data: any, index: number) => (
                <Select.Option
                  value={
                    screenName === 'Sub Recipe'
                      ? data?.id
                      : data?.storage_unit_id
                  }
                  key={index}
                >
                  {screenName === 'Sub Recipe'
                    ? data?.unit
                    : data?.storage_unit}
                </Select.Option>
              ))}
            </Select>
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['unit_id']}
              </div>
            )}
          </span>
          <span className="col">
            <div className="wizardLabel">
              Yield <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Enter yeild"
              size="large"
              type={'number'}
              value={handleFloat(yeild)}
              onChange={(e) => handleYeild(e.target.value)}
              required
            />
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
      {/*} </span>
        </div>
      </div> */}

      <div className="subRecipewizardSubContainer">
        <div className="row">
          <span className="col">
            <div className="wizardLabel">
              {component == 'productionRecipe'
                ? 'Production Recipe Waste %'
                : 'Sub Recipe Waste %'}{' '}
              {/* <span className="sterik">*</span> */}
            </div>
            <Input
              placeholder="Sub Recipe Waste %"
              size="large"
              type="number"
              value={handleFloat(waste)}
              onChange={(e) => handleWaste(e.target.value)}
              required
            />
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </span>
          <span className="col">
            <div className="wizardLabel"></div>
            <div className="wizardSubContainer sustainable-check-spacing">
              <Checkbox
                onChange={(e) => {
                  if (!update) {
                    if (!sustainability && ingredient?.length > 1) {
                      setIngredientRemove(true);
                    } else handleSustainability(e.target.checked);
                  }
                }}
                value={sustainability}
                checked={sustainability}
              >
                Sustainability
              </Checkbox>
            </div>
          </span>
        </div>
      </div>

      {sustainability && (
        <div className="sustainableContainer">
          <div className="subRecipewizardSubContainer">
            <div className="row">
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Ingredient Name (Sustainable)
                  <span className="sterik">*</span>
                </div>
                <Input
                  placeholder="Enter Ingredient Name"
                  size="large"
                  value={ingredientLabelName}
                  onChange={(e) => handleIngredientName(e.target.value)}
                  required
                />
                {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
              </span>
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Inventory Category
                  <span className="sterik">*</span>
                </div>
                <Select
                  size={'large'}
                  onChange={(e) => {
                    // let extractObj =
                    //   screenName === 'Sub Recipe'
                    //     ? units?.filter((object: any) => object?.id === e)
                    //     : units?.filter(
                    //         (object: any) => object?.storage_unit_id === e
                    //       );

                    handleIngredientCategory(e);
                  }}
                  className={
                    errorList['unit_id']?.length > 0
                      ? 'dropdownUnitColorRed'
                      : ''
                  }
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  placeholder="Select Category"
                  value={ingredientCategory}
                >
                  {ingredientCategoryList?.map((data: any, index: number) => (
                    <Select.Option value={data?.id} key={index}>
                      {data?.sub_category}
                    </Select.Option>
                  ))}
                </Select>
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['unit_id']}
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className="subRecipewizardSubContainer">
            <div className="row">
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Compostable Waste %<span className="sterik">*</span>
                </div>
                <Input
                  placeholder="Enter Compostable Waste %"
                  size="large"
                  type="number"
                  value={
                    compostableWastePercent
                    // ? handleFloat(+compostableWastePercent) : ''
                  }
                  onChange={(e) => handleWastePercent(e.target.value)}
                  required
                />
                {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
              </span>
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Compostable Waste Quantity
                  {/* <span className="sterik">*</span> */}
                </div>
                <Input
                  placeholder="Enter Compostable Waste Value"
                  size="large"
                  // type="number"
                  value={`${handleFloat(+compostableWasteValue)} ${unit?.unit}`}
                  disabled
                  // onChange={(e) => handleWaste(e.target.value)}
                  // required
                />
                {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
              </span>
            </div>
          </div>
          <div className="subRecipewizardSubContainer">
            <div className="row">
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Re-Usable Waste %{/* <span className="sterik">*</span> */}
                </div>
                <Input
                  placeholder="Less Than Sub Recipe Waste %"
                  size="large"
                  type="number"
                  value={handleFloat(+reuseWastePercent)}
                  disabled
                  // onChange={(e) => handleWaste(e.target.value)}
                  // required
                />
                {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
              </span>
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Re-Usable Waste Quantity
                  {/* <span className="sterik">*</span> */}
                </div>
                <Input
                  placeholder="Enter Re-Usable Waste Value"
                  size="large"
                  // type="number"
                  value={`${handleFloat(reuseWasteValue)} ${unit?.unit}`}
                  disabled
                  // onChange={(e) => handleWaste(e.target.value)}
                  // required
                />
                {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
              </span>
            </div>
          </div>
          {/* <div className="subRecipewizardSubContainer">
            <div className="row">
              <span className="sustainableCol">
                <div className="wizardLabel">
                  Ingredient Unit <span className="sterik">*</span>
                </div>
                <Select
                  size={'large'}
                  onChange={(e) => {
                    let extractObj = units?.find(
                      (object: any) => object?.storage_unit_id === e
                    );

                    handleIngredientUnit(extractObj);
                  }}
                  className={
                    errorList['unit_id']?.length > 0
                      ? 'dropdownUnitColorRed'
                      : ''
                  }
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  placeholder="Select Unit"
                  value={ingredientUnit?.unit}
                >
                  {units?.map((data: any, index: number) => (
                    <Select.Option value={data?.id} key={index}>
                      {data?.unit}
                    </Select.Option>
                  ))}
                </Select>
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['unit_id']}
                  </div>
                )}
              </span>
            </div>
          </div> */}
        </div>
      )}

      <div className="subRecipewizardSubContainer"></div>
    </React.Fragment>
  );
};
export default SustainabilityStepOne;
