/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Select,
  Space,
  Dropdown,
  Checkbox,
  Input,
  Tooltip,
  Button,
  Modal,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
  BuyingUnitModal,
} from '../../components';
import { useSelector } from 'react-redux';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import SupplierApi from '../../redux/middleware/supplier';
import IngredientActions from '../../redux/middleware/ingredient';
import BuyingUnitActions from '../../redux/middleware/buyingUnit';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Utils from '../../redux/utils';
import './SupplierIngredients.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';

const SupplierIngredients = () => {
  let buyingunitid = '';
  const navigate = useNavigate();
  const { state } = useLocation();
  const { supplier } = state;
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const shop = Utils.getCurrentShop();
  const [current, setCurrent] = useState(1);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [openBuyingUnitModal, setOpenBuyingUnitModal] = React.useState(false);
  const [taggedIngredients, setTaggedIngredients] = useState([{}]);
  const [unTaggedIngredients, setUnTaggedIngredients] = useState<any>([]);
  const [storageUnitData, setStorageUnitData] = useState<any>([]);
  const [buyingUnitData, setBuyingUnitData] = useState<any>([]);
  const [selectedBuyingUnit, setSelectedBuyingUnit] = useState<any>([]);
  const [buyingUnit, setBuyingUnit] = useState<any>({});
  const [newBuyingUnit, setNewBuyingUnit] = useState<any>();
  const [buyingUnitId, setBuyingUnitId] = useState<any>();
  const [supplierId, setSupplierId] = useState<any>();
  const [placeholder, setPlaceholder] = useState('Select Buying Unit');
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalUntaggedImage, setTotalUntaggedImage] = useState<
    number | undefined
  >(undefined);
  const [totalTaggedImage, setTotalTaggedImage] = useState<number | undefined>(
    undefined
  );
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [recordIndex, setRecordIndex] = useState(-1);
  const [storageFactor, setStorageFactor] = useState<any>();
  const [totalCost, setTotalCost] = useState<any>();
  const [markAsDefault, setMarkAsDefault] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [keyword, setKeyword] = useState('');
  const PageDataLength = [6, 10, 20, 50];
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [supplierName, setSupplierName] = useState('');
  const { currentCompany } = useSelector((state: any) => state.Auth);
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const { currentShop } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Upload Images'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    emptyAllStates();
    setCurrent(1);
    // fetchBuyingUnits();
    fetchUnits();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
      fetchIngredientCount();
    }
  }, [dataLength, current, check, currentBrand, currentCompany, currentShop]);

  const fetchIngredientCount = async () => {
    const shop = await Utils.getCurrentShop();
    const imagesData = check
      ? await SupplierApi.getSupplierUnTaggedIngredients(
          supplier?.id,
          1000,
          '',
          shop?.shop_id ? shop?.shop_id : ''
        )
      : await SupplierApi.getSupplierTaggedIngredients(
          supplier?.id,
          dataLength,
          current,
          shop?.shop_id ? shop?.shop_id : ''
        );
    if (imagesData === undefined || !imagesData?.success) {
      // setTotal(0);
      check
        ? setTotalTaggedImage(imagesData?.data?.pagination?.total)
        : setTotalUntaggedImage(imagesData?.data?.pagination?.total);
      // setTaggedIngredients([]);
      // setUnTaggedIngredients([]);
      // setSupplierName('');
    } else {
      if (check) {
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        // setSupplierName;
        // setTotal(imagesData?.data?.pagination?.total);
        // setTaggedIngredients(imagesData?.data?.items);
        // setTotalTaggedImage(imagesData?.data?.pagination?.total);
        // setSupplierName(imagesData?.data?.items[0].supplier_name);
      } else {
        setTotalTaggedImage(imagesData?.data?.pagination?.total);
        // setTotal(imagesData?.data?.pagination?.total);
        // setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        // setUnTaggedIngredients(imagesData?.data?.items);
        // setSupplierName(imagesData?.data?.items[0].supplier_name);
      }
    }
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  //   {
  //     key: '2',
  //     label: <div className="dotOption">Edit</div>,
  //     icon: <img src={Edit} width={15} />,
  //   },
  // ];
  const itemsForUntagged: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">View</div>,
      icon: <img src={Eye} width={15} className="iconDropdown" />,
    },
  ];

  // const addBuyingUnit = async (newbuyingunit: any) => {
  //   const param = {
  //     company_id: company?.id,
  //     buying_unit: newbuyingunit,
  //     supplier_id: supplierId,
  //   };
  //   try {
  //     const response = await BuyingUnitActions.addBuyingUnit(param);
  //     if (response?.success) {
  //       await fetchBuyingUnits();
  //     }
  //   } catch (error) {
  //     console.error('Error adding new buying unit:', error);
  //   }
  // };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const fetchUnits = async () => {
    const response = await SubRecipeActions.getStorageUnitsBySupplier(
      supplier?.id
    );
    setSupplierName(supplier?.supplier_name);
    setSupplierId(supplier?.id);
    if (response?.success) {
      setStorageUnitData(response?.data);
    } else {
      setStorageUnitData([]);
    }
  };

  // const fetchBuyingUnits = async () => {
  //   const response = await SupplierApi.getBuyingUnits(supplier?.id);
  //   setSupplierId(supplier?.id);
  //   if (response?.success) {
  //     setBuyingUnitData(response?.data?.items);
  // setSelectedBuyingUnit(response?.data?.items?.[0]?.buying_unit);
  // setBuyingUnitId(response?.data?.items?.[0]?.id);
  // const mappedUnits = response?.data?.items[0]?.storage_unit
  //   ?.map((unit: any) => unit?.storage_unit)
  //   .join(', ');
  // setStorageUnit(mappedUnits);
  // const buyingunit = response?.data?.items?.forEach((item: any) => {
  //   if (item?.buying_unit === newBuyingUnit?.buying_unit) {
  //     setBuyingUnitId(item?.id);
  //     buyingunitid = item?.id;
  //   }
  // });
  //   } else {
  //     setBuyingUnitData([]);
  //   }
  // };

  const onSubmit = () => {
    fetchUnits();
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        // handleDialogData();
        break;
      case 2:
        // setUpdate(true);
        // setOpenIngredientDialog(true);
        break;
      default:
    }
  };

  const onSelectMultiple = (e: CheckboxChangeEvent, record: any) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, record]);
    } else {
      setSelectedItems(
        selectedItems.filter((item: any) => item.id !== record.id)
      );
    }
  };

  const isItemSelected = (record: any) => {
    return (
      selectAll || selectedItems.some((item: any) => item.id === record.id)
    );
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedItems(unTaggedIngredients);
      setSelectAll(true);
    } else {
      setSelectedItems([]);
      setSelectAll(false);
    }
  };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">
                  {/* {record?.ingredient_name} */}

                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Name</div>
                        <div className="tootipText">
                          {record?.ingredient_name}
                        </div>
                      </div>
                    }
                  >
                    {record?.ingredient_name?.length > 20
                      ? record?.ingredient_name?.substring(0, 20) + '...'
                      : record?.ingredient_name}
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing override-column-2-style">
          {record?.storage_unit}
        </div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>TAGGED SUPPLIER</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: () => (
    //     <div className="col3 cellSpacing override-column-2-style">
    //       {supplier?.supplier_name}
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>MARK AS DEFAULT</span>
        </div>
      ),
      dataIndex: 'is_default',
      key: 'is_default',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <Checkbox
            disabled={recordIndex == index ? false : true}
            onChange={(e: any) => onChangeMarkAsDefault(e, record)}
            checked={markAsDefault?.id == record?.id}
          />
        ) : (
          <div className="col3 cellSpacing override-column-2-style ">
            {record?.is_default == 1 ? 'YES' : 'NO'}
          </div>
        ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BUYING UNIT</span>
        </div>
      ),
      dataIndex: 'buying_unit',
      key: 'buying_unit',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div className="col3 cellSpacing">
            <Select
              size={'large'}
              placeholder={'Select Buying Unit'}
              onChange={(e) => {
                const unit = buyingUnitData.find(
                  (data: any) => data?.id == e
                ) || { buying_unit: '' };
                setBuyingUnit(unit);
                // record.category = unit.buying_unit;
                // handleMenu(e, record);
              }}
              disabled={!(recordIndex == index)}
              style={{ width: 150 }}
              optionFilterProp="children"
              value={buyingUnit?.buying_unit}
            >
              {buyingUnitData?.map((data: any, index: number) => (
                <Select.Option value={data?.id} key={index}>
                  {data.buying_unit}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : (
          <div className="col3 cellSpacing override-column-2-style">
            {record?.buying_unit == null ? '-' : record?.buying_unit}
          </div>
        ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STORAGE FACTOR</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div className="col3 cellSpacing storageFactorInput">
            <Input
              placeholder="Enter Storage"
              type="number"
              disabled={!(recordIndex == index)}
              onChange={(e) => setStorageFactor(+e.target.value)}
              value={storageFactor}
            />
          </div>
        ) : (
          <div className="col3 cellSpacing override-column-2-style">
            {record?.buying_factor}
          </div>
        ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>STORAGE UNIT</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3 cellSpacing">{record?.unit}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div className="col3 cellSpacing storageFactorInput">
            <Input
              placeholder="Enter Cost"
              type="number"
              disabled={!(recordIndex == index)}
              onChange={(e) => setTotalCost(e.target.value)}
              value={totalCost}
            />
          </div>
        ) : (
          <div className="col3 cellSpacing override-column-2-style">
            {record?.total_cost}
          </div>
        ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any, index: number) =>
        recordIndex == index ? (
          <div
            className={`${
              loader ? 'loader-enable-supplier' : 'continue'
            } Supplier-ingredient-editButton`}
            onClick={() => {
              submitIngredient(record, 'tagged');
            }}
          >
            <Spin spinning={loader} size="default">
              UPDATE
            </Spin>
          </div>
        ) : (
          <div
            className={`continue Supplier-ingredient-editButton`}
            onClick={() => {
              setMarkAsDefault({});
              setRecordIndex(index);
              setStorageFactor(0);
              setBuyingUnit({
                buying_unit: record?.buying_unit,
                buying_unit_id: record?.buying_unit_id,
              });
              setStorageFactor(record?.buying_factor);
              setTotalCost(record?.total_cost);
              setMarkAsDefault(
                record?.is_default == 1 ? { id: record?.id } : {}
              );
            }}
          >
            EDIT
          </div>
        ),
    },
  ];
  const unTaggedColumns = [
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>
            <Checkbox onChange={onSelectAll} checked={selectAll} />
          </span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any, index: number) => (
        <div className="col3 cellSpacing sub-view-align-adjustment">
          <Checkbox
            onChange={(e: any) => {
              onSelectMultiple(e, record);
            }}
            checked={isItemSelected(record)}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font sub-view-align-adjustment">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title alignment-left-column-values">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Name</div>
                        <div className="tootipText">
                          {record?.ingredient_name}
                        </div>
                      </div>
                    }
                  >
                    {record?.ingredient_name?.length > 20
                      ? record?.ingredient_name?.substring(0, 20) + '...'
                      : record?.ingredient_name}
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.unit}</div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>UNTAGGED SUPPLIER</span>
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: () => (
    //     <div className="col3 cellSpacing">{supplier?.supplier_name}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>MARK AS DEFAULT</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any, index: number) => (
        <div className="col3 cellSpacing">
          {' '}
          <Checkbox
            // disabled={recordIndex == index ? false : true}
            onChange={(e: any) => onChangeMarkAsDefault(e, record)}
            checked={markAsDefault?.id == record?.id}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>BUYING UNIT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        let input = '';
        const inputValue = input && input.length > 0 ? input : null;
        let relevantBuyingUnits: any[] = [];
        const matchingStorageUnit = storageUnitData.find(
          (storageUnitItem: any) =>
            record.storage_unit === storageUnitItem.storage_unit
        );
        if (matchingStorageUnit) {
          relevantBuyingUnits = matchingStorageUnit.buying_units;
          setBuyingUnitData(relevantBuyingUnits);
          if (relevantBuyingUnits.length > 0) {
            setSelectedBuyingUnit(relevantBuyingUnits[0]?.buying_unit);
            setBuyingUnitId(relevantBuyingUnits[0]?.id);
          }
        }
        return (
          <div className="col3 cellSpacing">
            <Select
              size="large"
              placeholder="Select or Create Buying Unit"
              onSearch={(e) => {
                input = e;
                setNewBuyingUnit(e);
              }}
              onChange={(e) => {
                const unit = relevantBuyingUnits.find(
                  (data: any) => data?.id == e
                ) || { buying_unit: newBuyingUnit };
                setBuyingUnit((prevUnits: any) => ({
                  ...prevUnits,
                  [record.id]: unit,
                }));
                if (e === newBuyingUnit) {
                  setOpenBuyingUnitModal(true);
                }
                setNewBuyingUnit(unit);
              }}
              style={{ width: 150 }}
              optionFilterProp="children"
              defaultValue={
                relevantBuyingUnits[0]?.buying_unit ||
                buyingUnit[index]?.buying_unit
              }
              showSearch
              filterOption={(input, option: any) =>
                option.children
                  ? option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              <>
                {relevantBuyingUnits?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.buying_unit}
                  </Select.Option>
                ))}
                {!relevantBuyingUnits.length && !inputValue && (
                  <Select.Option disabled value={null}>
                    No buying unit available
                  </Select.Option>
                )}
                {newBuyingUnit && newBuyingUnit?.length > 0 && (
                  <Select.Option value={newBuyingUnit}>
                    {`Create ${newBuyingUnit} as buying unit`}
                  </Select.Option>
                )}
              </>
            </Select>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STORAGE UNIT</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col2 cellSpacing alignment-center-column-values">
            {record?.storage_unit}
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STORAGE FACTOR</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing storageFactorInput">
          <Input
            placeholder="Enter Storage Factor"
            type="number"
            defaultValue={storageFactor[record.id] || 1}
            // disabled={!(recordIndex == index)}
            onChange={(e) => {
              const updatedStorageFactor = {
                ...storageFactor,
                [record.id]: e.target.value,
              };
              setStorageFactor(updatedStorageFactor);
            }}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing storageFactorInput">
          <Input
            placeholder="Enter Cost"
            type="number"
            value={totalCost[record.id] || ''}
            // value={(recordIndex == index && totalCost) || ''}
            // disabled={!(recordIndex == index)}
            onChange={(e) => {
              const updatedTotalCost = {
                ...totalCost,
                [record.id]: e.target.value,
              };
              setTotalCost(updatedTotalCost);
            }}
          />
        </div>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (_: any, record: any, index: number) =>
    //     recordIndex == index ? (
    //       <div
    //         className={`${
    //           loader ? 'loader-enable-supplier' : 'continue'
    //         } Supplier-ingredient-editButton`}
    //         onClick={async () => {
    //           if (newBuyingUnit?.buying_unit) {
    //             await addBuyingUnit(newBuyingUnit?.buying_unit);
    //             submitIngredient(record, 'untagged');
    //           } else {
    //             submitIngredient(record, 'untagged');
    //           }
    //         }}
    //       >
    //         <Spin spinning={loader} size="default">
    //           UPDATE
    //         </Spin>
    //       </div>
    //     ) : (
    //       <div
    //         className={`continue Supplier-ingredient-editButton`}
    //         onClick={() => {
    //           if (shop) {
    //             setMarkAsDefault({});
    //             setRecordIndex(index);
    //             setStorageFactor('');
    //             setBuyingUnit({});
    //             setTotalCost('');
    //           } else {
    //             setIsModalVisible(true);
    //           }
    //         }}
    //       >
    //         EDIT
    //       </div>
    //     ),
    // },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: () => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items: itemsForUntagged,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              // setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const emptyAllStates = () => {
    setSelectedItems([]);
    setBuyingUnit({});
    setMarkAsDefault(null);
    setStorageFactor('');
    setTotalCost('');
    setRecordIndex(-1);
  };

  const onChangeMarkAsDefault = (e: CheckboxChangeEvent, data: any) => {
    if (e.target.checked) setMarkAsDefault(data);
    else setMarkAsDefault({});
  };

  const submitIngredient = async (data: any, type: string) => {
    // const brand = await Utils.getCurrentBrand();
    // const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    // const user = await Utils.getCurrentUser();
    const filteredIngredients = unTaggedIngredients?.filter((data: any) =>
      selectedItems.some((item: any) => item.id === data.id)
    );
    const payload = {
      shop_id: shop?.shop_id ? shop?.shop_id : 0,
      supplier_id: supplier?.id,
      buying_unit_id:
        buyingUnit?.id || buyingUnit?.buying_unit_id || buyingunitid || 0,
      buying_factor: storageFactor || 1,
      total_cost: totalCost,
      is_default: markAsDefault?.id == data?.id ? 1 : 0,
      // ingredient_name: data?.ingredient_name,
      // brand_id: brand?.brand_id,
      // unit_id: data?.unit_id,
      // waste: data?.waste || 0,
      // ingredient_category_id: data?.ingredient_category_id,
      // product_code: data?.product_code,
      // company_id: company?.id,
      // calorie: data?.calorie,
      // carb: data?.carb,a
      // protein: data?.protein,
      // fat: data?.fat,
      // allergens_id: data?.allergens_id,
      // is_verified: data?.is_verified || 0,
      // verified_by: data?.verified_by || '',
      // unit_cost: '0.00',
      // created_by: user?.id,
    };

    const bulkpayload = {
      shop_id: shop?.shop_id ? shop?.shop_id : 0,
      supplier_id: supplier?.id,
      ingredients: filteredIngredients?.map((data: any) => ({
        sku_id: data?.sku_id,
        buying_unit_id:
          buyingUnit[data.id]?.id ||
          buyingUnit[data.id]?.buying_unit_id ||
          buyingunitid ||
          buyingUnitId,
        buying_factor: storageFactor[data.id] || 1,
        total_cost: totalCost[data.id],
        is_default: markAsDefault?.id == data?.id ? 1 : 0,
      })),
    };

    try {
      setLoader(true);
      const response =
        type == 'tagged'
          ? await IngredientActions.addSuplierToInventory(payload, data?.sku_id)
          : await IngredientActions.addBulkSuplierToInventory(bulkpayload);
      setLoader(false);
      if (response?.success) {
        emptyAllStates();
        setRecordIndex(-1);
        pageData();
        fetchIngredientCount();
        // let responseDialogData = {
        //   OnClick: () => {
        //     setRecordIndex(-1);
        //     pageData();
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${data?.ingredient_name}`, ` has been Tagged`],
        // };
        // ResponseDialog(responseDialogData);
      } else if (!response?.success) {
        let responseDialogData = {
          OnClick: () => {
            emptyAllStates();
          },
          type: 'error',
          messageArray: ['Error!', `${response?.message}`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      const responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Validation Failed!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const pageData = async () => {
    setRecipeLoader(true);
    const shop = await Utils.getCurrentShop();
    const imagesData = check
      ? await SupplierApi.getSupplierTaggedIngredients(
          supplier?.id,
          dataLength,
          current,
          shop?.shop_id ? shop?.shop_id : ''
        )
      : await SupplierApi.getSupplierUnTaggedIngredients(
          supplier?.id,
          1000,
          '',
          shop?.shop_id ? shop?.shop_id : ''
        );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      check ? setTotalUntaggedImage(0) : setTotalTaggedImage(0);
      setTaggedIngredients([]);
      setUnTaggedIngredients([]);
      setSupplierName('');
    } else {
      if (check) {
        setSupplierName;
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedIngredients(imagesData?.data?.items);
        setTotalTaggedImage(imagesData?.data?.pagination?.total);
        // setSupplierName(imagesData?.data?.items[0].supplier_name);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUnTaggedIngredients(imagesData?.data?.items);
        // setSupplierName(imagesData?.data?.items[0].supplier_name);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const imagesData = check
      ? await SupplierApi.getSupplierSearchIngredients(
          supplier?.id,
          dataLength,
          keyword,
          'supplier_id'
        )
      : await SupplierApi.getSupplierSearchIngredients(
          supplier?.id,
          1000,
          keyword,
          'not_supplier_id'
        );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      check ? setTotalUntaggedImage(0) : setTotalTaggedImage(0);
      setTaggedIngredients([]);
      setUnTaggedIngredients([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedIngredients(imagesData?.data?.items);
        setTotalTaggedImage(imagesData?.data?.pagination?.total);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUnTaggedIngredients(imagesData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  // const actionButton = (
  //   <Button
  //     text="Add New"
  //     className="theme-button recipeBtn"
  //     icon={<PlusOutlined />}
  //     handleButtonClick={() => {
  //       navigate('wizard', { state: { update: false, id: '' } });
  //     }}
  //     shape="round"
  //     size="middle"
  //   />
  // );

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Supplier Ingredients"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/supply');
        }}
        button={() => {}}
        perm_status={permissionStatus}
      />

      {openBuyingUnitModal ? (
        <BuyingUnitModal
          open={openBuyingUnitModal}
          handleCancel={() => setOpenBuyingUnitModal(false)}
          component="Buying-Unit"
          buyingUnit={newBuyingUnit?.buying_unit}
          supplierId={supplierId}
          supplier={supplierName}
          onSubmit={onSubmit}
        />
      ) : null}

      <Page title="Supplier Ingredients">
        <div className="supplierHeading">
          <p className="heading"> Supplier Name:</p>
          <h1 className="name">{supplier?.supplier_name}</h1>
        </div>
        <Row className="listingTopFilter">
          {/* <Col lg={10}></Col> */}

          <Col lg={16}>
            <div className="SupplierIngredients-list-menu-container">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div
                  onClick={() => {
                    setCheck(true);
                    setRecordIndex(-1);
                  }}
                  className="text SupplierIngredients-list-menu-style"
                >
                  Matched
                </div>
                <div
                  className={`SupplierIngredients-list-counter ${
                    totalUntaggedImage &&
                    totalUntaggedImage >= 10 &&
                    'SupplierIngredients-list-large-counter'
                  }`}
                >
                  {totalTaggedImage}
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => {
                  setCheck(false);
                  setRecordIndex(-1);
                }}
              >
                <div className="text SupplierIngredients-list-menu-style">
                  UnMatched
                </div>

                <div
                  className={`SupplierIngredients-list-counter ${
                    totalUntaggedImage &&
                    totalUntaggedImage >= 10 &&
                    'SupplierIngredients-list-large-counter'
                  }`}
                >
                  {totalUntaggedImage}
                </div>
              </div>
            </div>
          </Col>

          <Col lg={8} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  action={onSearch}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  placeholderText="Search..."
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12} className="listingTopFilter">
          <Col lg={24} md={16} className="SupplierIngredients-list-wrapper">
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={check ? dataLength : 1000}
                items={''}
                dataSource={
                  check ? [...taggedIngredients] : [...unTaggedIngredients]
                }
                columns={check ? taggedColumns : unTaggedColumns}
                component="supplier"
                {...(!check && { scrollY: 600 })}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            {check ? (
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
        <div className="butonGroup po-btn">
          <div
            className="wizard-button"
            onClick={() => navigate('/app/supply')}
          >
            Back
          </div>
          <div
            className="continue wizard-button"
            onClick={async () => {
              const filteredIngredients = unTaggedIngredients?.filter(
                (data: any) =>
                  selectedItems.some((item: any) => item.id === data.id)
              );
              if (newBuyingUnit?.buying_unit) {
                // await addBuyingUnit(newBuyingUnit?.buying_unit);
                submitIngredient(filteredIngredients, 'untagged');
              } else {
                submitIngredient(filteredIngredients, 'untagged');
              }
            }}
          >
            Continue
          </div>
        </div>
      </Page>
      <Modal
        title={<div className="title font">Supplier Ingredients</div>}
        centered
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={480}
        footer={null}
        className="deleteConfirmationContainer"
      >
        <div className="deleteDialogText">
          Please create a branch before editing Supplier Ingredients
        </div>
        <div className="butonGroupDialog">
          <div
            className="wizard-button"
            onClick={() => setIsModalVisible(false)}
          >
            Ok
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default SupplierIngredients;
