/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './UserPersonalInfo.scss';
import Utils from '../../redux/utils';
import { Breadcrumb } from '../../components';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import UserWizardForm from '../userListWizard/userWizard';
import Wizard from '../CompanyWizard/wizard';
// import SubWizard from '../subscriptionWizard/wizard';
import Plans from './PricePlans';

const UserConfiguration = () => {
  // eslint-disable-next-line no-unused-vars
  const { id } = Utils.getCurrentUser();
  const companyInfo = Utils.getCurrentCompany();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState('1');
  // eslint-disable-next-line no-unused-vars
  const [processStep, setProcessStep] = React.useState('1');
  const [disable, setDisable] = React.useState(false);

  const onChangeTabs = (e: any) => {
    const intActiveTab = parseInt(activeTab);
    const selectedTab = parseInt(e);
    const pStep = parseInt(processStep);
    if (selectedTab < intActiveTab) {
      setActiveTab(`${selectedTab}`);
    }
    if (selectedTab == 2 && intActiveTab == 3) {
      setDisable(true);
    }
    if (selectedTab >= intActiveTab && selectedTab <= pStep) {
      setActiveTab(`${selectedTab}`);
    }
  };

  const onProceedNext = (value: any) => {
    setActiveTab(value);
    setProcessStep(value);
  };

  const Items: any[] = [
    {
      key: '1',
      label: `Personal Information`,
      children: (
        <UserWizardForm
          id={id}
          onSetupSuccess={onProceedNext}
          update
          bypassPermission
        />
      ),
    },
    {
      key: '2',
      label: `Subscription Details`,
      children: (
        <Plans disable={disable} id={id} onSetupSuccess={onProceedNext} />
      ),
    },
    {
      key: '3',
      label: `Company Information`,
      children: <Wizard id={companyInfo?.id} update bypassPermission />,
    },
  ];

  return (
    <React.Fragment>
      <div className="personalInformation">
        <Breadcrumb
          heading="Setup Profile"
          breadcrumbIcon
          iconAction={() => {
            navigate('/app');
          }}
        />
        <div className="configurationTabContainer">
          <Tabs
            items={Items}
            activeKey={activeTab}
            onChange={(e) => onChangeTabs(e)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserConfiguration;
