/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Card,
  Row,
  Col,
  Dropdown,
  Space,
  Radio,
  RadioChangeEvent,
  Button,
  Switch,
  Modal,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from './breadcrumb';
import BreadcrumbIcon from './breadcrumbIcon';
import { useLocation } from 'react-router-dom';
import { Button as CSButton } from '../../components';
// import MarketListForRB from '../../redux/middleware/marketListForRecipeBuilder';
import './styles.scss';
import type { MenuProps } from 'antd';
import Utils from '../../redux/utils';
import { useTranslation } from 'react-i18next';
import GridViewIcon from '../../assets/images/GridView.png';
import Attributes from '../../redux/middleware/attributes';
import Brand from '../../assets/images/Brand1.png';

type breadcrumbProps = {
  button?: any;
  breadcrumbIcon?: boolean;
  iconAction: () => void;
  heading: any;
  perm_status?: any;
  TaggedDropdown?: any;
  handleCSV?: any;
  options?: any;
};

const sampleCashPurchaseCSV =
  'https://admin.chefadmin.com/sample_csv/sample_cash_purchase.csv';

const sampleRecipeCSV =
  'https://admin.chefadmin.com/sample_csv/sample_upload_recipes.csv';

const sampleSubRecipeCSV =
  'https://admin.chefadmin.com/sample_csv/sample_upload_sub_recipes.csv';

const sampleBulkRecipeCSV =
  'https://admin.chefadmin.com/public/sample_csv/sample_upload_recipes.csv';
const sampleBulkRecipeForMarketlist =
  'https://admin.chefadmin.com/sample_csv/sample_marketlist.csv';

const sampleRDUploadMArketList =
  'https://admin.chefadmin.com/public/sample_csv/sample_rb_marketlist.csv';

const sampleBulkUploadRDSubRecipe =
  'https://admin.chefadmin.com/public/sample_csv/sample_upload_recipes.csv';
const sampleBulkUploadRDRecipe =
  'https://admin.chefadmin.com/public/sample_csv/sample_upload_sub_recipes.csv';
export default function breadcrumb(props: breadcrumbProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  const { company_profile_status } = user;
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'branch',
  });

  const itemsForRD: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Recipe</div>,
    },
    {
      key: '2',
      label: <div className="dotOption">Sub Recipe</div>,
    },
  ];

  const handleAddNewType: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    let type = 0;
    switch (option) {
      case 1:
        navigate('/app/bulkUploadR&DRecipe');
        break;
      case 2:
        navigate('/app/bulkUploadR&DSubRecipe');
        break;
      default:
    }
  };

  // const onChange = (e: RadioChangeEvent) => {};
  // const handleOptionChange = (option: string) => {};

  const handleChange = (checked: any) => {
    console.log(`Switch is ${checked ? 'checked' : 'unchecked'}`);
  };

  const handleCSVForStock = async () => {
    setLoader(true);
    const response = await Attributes.exportCSVForBulkUploadCP();
    setLoader(false);
    const { data } = response;
    window.open(data?.url);
  };

  return (
    <>
      <Card className={company_profile_status === 0 ? 'userRestrict' : ''}>
        <Row className="breadcrumbMainWrapper">
          <div className="breadcrumb-wrapper">
            {location.pathname != '/' && props.breadcrumbIcon && (
              <div className="breadcrumb-button">
                <BreadcrumbIcon
                  className="breadcrumb-icon"
                  handleClick={props.iconAction}
                  shape="circle"
                  size="large"
                />
              </div>
            )}
            <div className="breadcrumb">
              <h1 className="breadcrumb-heading">{props?.heading}</h1>
              <BreadCrumb homepath={t('dashboard')} title={props?.heading} />
            </div>
          </div>
          {/* {user?.roles[0]?.id === 2 && props?.heading === 'Dashboard' ? (
          <Col className="breadcrumb-switch" lg={11}>
            <Switch
              className="gridIconContainer"
              checkedChildren="Brand"
              unCheckedChildren="Branch"
              // defaultChecked
              onChange={handleChange}
            />
            <Radio.Group
              className="inventoryFilter-Radio"
              onChange={onChange}
              value=""
            >
              <Radio
                className="radio-button radio-button-breadcrumb"
                name="brand"
                value="brand"
              >
                Brand
              </Radio>
              <Radio className="radio-button" name="branch" value="branch">
                Branch
              </Radio>
            </Radio.Group> 
          </Col>
        ) : null} */}
          {props?.heading === 'Upload Market List' && (
            <Col className="recipeBtn-wrapper" lg={15}>
              <CSButton
                text="Download Sample CSV"
                className="theme-button recipeBtn"
                handleButtonClick={() =>
                  openInNewTab(sampleBulkRecipeForMarketlist)
                }
                shape="round"
                size="middle"
              />
            </Col>
          )}
          {props?.heading == 'Upload Recipes' ||
          props?.heading === 'Upload Sub Recipes' ||
          props?.heading === 'Upload Cash Purchase' ||
          props?.heading === 'RB Upload MarketList' ||
          props?.heading === 'R&D Bulk Upload Sub Recipe' ||
          props?.heading === 'R&D Bulk Upload Recipe' ||
          props?.heading === 'Upload Ingredients' ? (
            <Col className="recipeBtn-wrapper" lg={15}>
              <>
                {/* <CSButton
                text="Get Marketlist"
                className="theme-button recipeBtn"
                handleButtonClick={() => RecipeActions.getMarketList()}
                shape="round"
                size="middle"
              />
        */}
              </>
              {props?.heading === 'Upload Sub Recipes' && (
                <>
                  <CSButton
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() => openInNewTab(sampleSubRecipeCSV)}
                    shape="round"
                    size="middle"
                  />
                </>
              )}
              {props?.heading === 'Upload Recipes' && (
                <>
                  <CSButton
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() => openInNewTab(sampleRecipeCSV)}
                    shape="round"
                    size="middle"
                  />
                </>
              )}
              {props?.heading === 'Upload Cash Purchase' && (
                <>
                  <CSButton
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() =>
                      openInNewTab(sampleCashPurchaseCSV)
                    }
                    shape="round"
                    size="middle"
                  />
                  <CSButton
                    text="Export SKU CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() => handleCSVForStock()}
                    shape="round"
                    size="middle"
                  />
                </>
              )}
              {props?.heading === 'RB Upload MarketList' && (
                <>
                  <CSButton
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() =>
                      openInNewTab(sampleRDUploadMArketList)
                    }
                    shape="round"
                    size="middle"
                  />
                </>
              )}
              {props?.heading === 'R&D Bulk Upload Sub Recipe' && (
                <>
                  <CSButton
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() => openInNewTab(sampleBulkRecipeCSV)}
                    shape="round"
                    size="middle"
                  />
                </>
              )}
              {props?.heading === 'R&D Bulk Upload Recipe' && (
                <>
                  <CSButton
                    text="Download Sample CSV"
                    className="theme-button recipeBtn"
                    handleButtonClick={() => openInNewTab(sampleBulkRecipeCSV)}
                    shape="round"
                    size="middle"
                  />
                </>
              )}
              {/* Upload Ingredients */}
              {props?.heading === 'Upload Ingredients' &&
                props?.perm_status === 2 && (
                  <>
                    <CSButton
                      text="Download Sample CSV"
                      className="theme-button recipeBtn"
                      handleButtonClick={() =>
                        openInNewTab(sampleBulkRecipeForMarketlist)
                      }
                      shape="round"
                      size="middle"
                    />
                  </>
                )}
            </Col>
          ) : (
            <Col className="breadcrumb-right" lg={7}>
              {props?.heading === 'R&D Sub Recipe' &&
              props?.perm_status === 2 &&
              user?.signup_source === 'chefadmin' ? (
                <Dropdown
                  menu={{ items: itemsForRD, onClick: handleAddNewType }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                >
                  <Button
                    className="theme-button recipeBtn uploadMarketListButton"
                    shape="round"
                    size="middle"
                  >
                    Bulk Upload
                  </Button>
                </Dropdown>
              ) : null}
              {props?.heading === 'R&D Recipe List' &&
              props?.perm_status === 2 &&
              user?.signup_source === 'chefadmin' ? (
                <Dropdown
                  menu={{ items: itemsForRD, onClick: handleAddNewType }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                >
                  <Button
                    className="theme-button recipeBtn uploadMarketListButton"
                    shape="round"
                    size="middle"
                  >
                    Bulk Upload
                  </Button>
                </Dropdown>
              ) : null}
              {props?.heading === 'R&D Market List' &&
              props?.perm_status === 2 ? (
                <CSButton
                  text="Upload Marketlist"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() => navigate('/app/R&DuploadMarketList')}
                  shape="round"
                  size="middle"
                />
              ) : null}
              {/* {props?.heading === 'Inventory List' && props?.perm_status === 2 ? ( */}
              {props?.heading === 'Inventory List' &&
              props?.perm_status !== 2 ? (
                <CSButton
                  text="Count Stock"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() => navigate('/app/countStock')}
                  shape="round"
                  size="middle"
                />
              ) : null}
              {props?.heading === 'Inventory List' &&
              props?.perm_status === 2 &&
              props.options ? (
                <div className="ingredients-button-container">
                  {props.options()}
                </div>
              ) : null}
              {props?.heading === 'Stock Taking' ? (
                <CSButton
                  text="Count Stock"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() => navigate('/app/countStock')}
                  shape="round"
                  size="middle"
                />
              ) : null}
              {props?.heading === 'Recipes' && props?.perm_status === 2 ? (
                <CSButton
                  text="Bulk Upload"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() =>
                    navigate('/app/bulkUpload', {
                      state: { component: 'recipe' },
                    })
                  }
                  shape="round"
                  size="middle"
                />
              ) : null}
              {props?.heading === 'Sub Recipe' && props?.perm_status === 2 ? (
                <CSButton
                  text="Bulk Upload"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() =>
                    navigate('/app/bulkUpload', {
                      state: { component: 'sub_recipe' },
                    })
                  }
                  shape="round"
                  size="middle"
                />
              ) : null}
              {props?.heading === 'Cash Purchase' &&
              props?.perm_status === 2 ? (
                <CSButton
                  text="Bulk Upload"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() => {
                    if (shop) {
                      navigate('/app/bulkUpload', {
                        state: { component: 'cash_purchase' },
                      });
                    } else {
                      setIsModalVisible(true);
                    }
                  }}
                  shape="round"
                  size="middle"
                />
              ) : null}
              {props?.heading === 'Recipes' && props?.perm_status === 2 ? (
                <CSButton
                  text="Upload Images"
                  className="theme-button recipeBtn uploadMarketListButton"
                  handleButtonClick={() => navigate('/app/uploadimage')}
                  shape="round"
                  size="middle"
                />
              ) : null}

              {/* {props?.heading == 'Supplier' && (
              <Dropdown
                overlayStyle={{ height: '100px' }}
                menu={props?.TaggedDropdown}
                trigger={['click']}
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                className="supplyIngredientBreadcrumbDropdown"
              >
                <Space>
                  <div className="taggedSupplierButton">
                    <div>Tagged Ingredients</div>
                    <img
                      src={ArrowDownBlack}
                      alt=""
                      className="supplierIngredientDownArrow"
                    />
                  </div>
                </Space>
              </Dropdown>
            )} */}
              {props?.perm_status === 2 || !props?.perm_status
                ? props.button
                : null}
            </Col>
          )}
        </Row>
      </Card>
      <Modal
        title={<div className="title font">Cash Purchase</div>}
        centered
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={480}
        footer={null}
        className="deleteConfirmationContainer"
      >
        <div className="deleteDialogText">
          Please create a branch before bulk uploading Cash Purchase
        </div>
        <div className="butonGroupDialog">
          <div
            className="wizard-button"
            onClick={() => setIsModalVisible(false)}
          >
            Ok
          </div>
        </div>
      </Modal>
    </>
  );
}
