import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../components';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import UserWizardForm from './userWizard';

const UserWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const { t } = useTranslation('translation', {
    keyPrefix: 'users',
  });

  return (
    <>
      <Breadcrumb
        heading={t('add_new_user')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/users');
        }}
      />
      <UserWizardForm id={id} update={update} />
    </>
  );
};

export default UserWizard;
