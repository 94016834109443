import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import useClickListener from '../../../../helper/outsideClickListener';
// import Verified from '../../assets/images/Verified.png';
import SubMenu from '../../../../assets/images/SubMenu.svg';
import {
  setRecipeIngredients,
  setDatabaseIngredient,
} from '../../../../redux/reducers/recipeSlice';
import { store } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import './styles.scss';
import Utils from '../../../../redux/utils';
import RecipeActions from '../../../../redux/middleware/menu';
import SubRecipeActions from '../../../../redux/middleware/subRecipe';
import { useSelector } from 'react-redux';

type Props = {
  handleIngredientName: any;
  ingredientName: any;
  ingredientData: any;
  wasteType: any;
  handleIngredient: any;
  type_id: any;
  setApiLoader: any;
  tag?: string;
};

const DropdownComponent = (props: Props) => {
  let {
    handleIngredientName,
    ingredientName,
    handleIngredient,
    wasteType,
    type_id,
    setApiLoader,
    tag,
  } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const dispatch = useDispatch();
  const state = store.getState();
  const [ingredients, setIngredients] = useState([]);
  const [search, setSearch] = useState('');
  const [filterOptions] = useState([
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'I', value: 'I' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'M', value: 'M' },
    { key: 'N', value: 'N' },
    { key: 'O', value: 'O' },
    { key: 'P', value: 'P' },
    { key: 'Q', value: 'Q' },
    { key: 'R', value: 'R' },
    { key: 'S', value: 'S' },
    { key: 'T', value: 'T' },
    { key: 'U', value: 'U' },
    { key: 'V', value: 'V' },
    { key: 'W', value: 'W' },
    { key: 'X', value: 'X' },
    { key: 'Y', value: 'Y' },
    { key: 'Z', value: 'Z' },
  ]);

  const [searchResults, setSearchResults] = useState([] as any);
  const { currentBrand } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    const selectedDb = state.Recipe.selectedDatabase;
    // handleFilterIngredient('');
    if (wasteType?.label !== selectedDb) {
      dispatch(setDatabaseIngredient(wasteType?.label));
    }
  }, [wasteType]);

  useEffect(() => {
    // setSelectedFilter('A');
    setSearch(ingredientName);
    // handleIngredientsData();
  }, []);

  useEffect(() => {
    if (type_id) {
      setSearch(ingredientName);
      setApiLoader(true);
      handleIngredientName('', '', 1);
    }
  }, [type_id, currentBrand]);

  useEffect(() => {
    if (type_id) {
      handleIngredientsData();
    }
  }, [type_id, currentBrand, tag]);

  // const handleIngredientsData = async () => {
  //   const recipeIngredient = state.Recipe.ingredientsRecipe;
  //   if (recipeIngredient.length === 0) {
  //     handleFilterIngredient('a');
  //   } else {
  //     setIngredients(recipeIngredient);
  //     setSpinner(false);
  //   }
  // };

  const handleIngredientsData = () => {
    handleFilterIngredient('');
  };

  const handleFilterIngredient = async (e: string) => {
    const data =
      type_id == 2
        ? await RecipeActions.getMenu(
            300,
            1,
            Utils.getCurrentBrand()?.brand_id,
            e
          )
        : // : await SubRecipeActions.getSubRecipeInventoryByCharacter(3, e, '');
          await SubRecipeActions.searchAllSubRecipeInventory(
            3,
            1,
            300,
            Utils.getCurrentBrand()?.brand_id,
            Utils.getCurrentShop()?.shop_id
              ? Utils.getCurrentShop()?.shop_id
              : '',
            e,
            '',
            null,
            1,
            tag
          );
    if (data != undefined && data?.success) {
      setIngredients(data?.data?.items);
      dispatch(setRecipeIngredients(data?.data?.items));
    } else {
      setIngredients([]);
      dispatch(setRecipeIngredients([]));
    }
    setSpinner(false);
    setApiLoader(false);
  };

  const handleSearchIngredient = async (e: string) => {
    setSpinner(true);
    const data =
      type_id == 2
        ? await RecipeActions.searchMenu(300, 1, e)
        : await SubRecipeActions.searchAllSubRecipeInventory(
            3,
            1,
            300,
            Utils.getCurrentBrand()?.brand_id,
            Utils.getCurrentShop()?.shop_id
              ? Utils.getCurrentShop()?.shop_id
              : '',
            e
          );
    if (data != undefined && data?.success) setSearchResults(data?.data?.items);
    else setSearchResults([]);
    setSpinner(false);
  };

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
    setShowListViewOne(true);
  };

  const handleChange = (e: any) => {
    handleIngredientName(e);
    if (!isComponentVisible) {
      setIsComponentVisible(true);
    }
    if (e !== '') {
      setShowListViewOne(false);
      handleSearchIngredient(search);
    } else {
      setShowListViewOne(true);
      setSearchResults([]);
    }
  };

  const handleSearchFunction = () => {
    handleChange(search);
  };

  const handleSelectOption = (ingredient: any) => {
    if (type_id === 1) {
      handleIngredientName(
        ingredient?.ingredient_name,
        ingredient?.variation_list
      );
      setSearch(ingredient?.ingredient_name);
    } else {
      handleIngredientName(ingredient?.recipe_name, ingredient?.variation_list);
      setSearch(ingredient?.recipe_name);
    }

    handleIngredient(ingredient);
    setIsComponentVisible(false);
  };

  const handleFilterOption = (filterOption: any) => {
    setSpinner(true);
    setSelectedFilter(filterOption?.key);
    handleFilterIngredient(filterOption?.key);
    setIngredients(ingredients);
  };

  return (
    <div className="form-group" ref={ref}>
      <input
        type="text"
        placeholder={type_id === 2 ? 'Enter Menu' : 'Enter Ingredients'}
        onClick={handleClick}
        onChange={(e) => {
          handleIngredientName(e.target.value);
          setSearch(e.target.value);
        }}
        onKeyUp={() => handleSearchFunction()}
        value={ingredientName}
      />
      {isComponentVisible ? (
        <ul className="dropdown-list">
          {showListViewOne ? (
            <li className="main-list">
              <div className="list-btn">
                {filterOptions.map((filterOption, index) => {
                  return (
                    <>
                      <p
                        key={index}
                        className={
                          selectedFilter === filterOption?.key ? 'active' : ''
                        }
                        onClick={() => handleFilterOption(filterOption)}
                      >
                        {filterOption?.key}
                      </p>
                    </>
                  );
                })}
              </div>
              <ul className="subMenu">
                {/*  <li>
                  <h4 className="placeholder">
                    {component !== 'purchase order'
                      ? 'Select an option or create one'
                      : 'Select an option'}
                  </h4>
              </li> */}
                <li>
                  <h3>{selectedFilter ? selectedFilter : ''}</h3>
                </li>

                {spinner ? (
                  <Spin
                    spinning={spinner}
                    size="default"
                    className="recipeLoader"
                  />
                ) : (
                  ingredients.map((item: any, index: number) => {
                    return (
                      <>
                        {type_id === 2 ? (
                          <li
                            key={index}
                            style={{ display: 'flex', gap: '5px' }}
                            onClick={() => handleSelectOption(item)}
                            className="IconStyling"
                          >
                            <p>{item?.recipe_name}</p>
                          </li>
                        ) : (
                          <li
                            key={index}
                            style={{ display: 'flex', gap: '5px' }}
                            onClick={() => handleSelectOption(item)}
                            className="IconStyling"
                          >
                            <p>{item?.ingredient_name}</p>

                            {item?.ingredient_type == 'sub_recipe' && (
                              <img
                                src={SubMenu}
                                width={15}
                                alt=""
                                className="subRecipeIcons"
                              />
                            )}
                          </li>
                        )}
                      </>
                    );
                  })
                )}
              </ul>
            </li>
          ) : (
            <li>
              <ul className="searchMenu">
                {spinner ? (
                  <Spin
                    spinning={spinner}
                    size="default"
                    className="loaderStyle"
                  />
                ) : (
                  searchResults.map((item: any, index: number) => {
                    return (
                      <>
                        {type_id === 2 ? (
                          <li
                            key={index}
                            style={{ display: 'flex', gap: '5px' }}
                            onClick={() => handleSelectOption(item)}
                          >
                            <p>{item?.recipe_name}</p>
                          </li>
                        ) : (
                          <li
                            key={index}
                            style={{ display: 'flex', gap: '5px' }}
                            onClick={() => handleSelectOption(item)}
                          >
                            <p>{item?.ingredient_name}</p>

                            {item?.ingredient_type == 'sub_recipe' && (
                              <img
                                src={SubMenu}
                                width={15}
                                alt=""
                                className="subRecipeIcons"
                              />
                            )}
                          </li>
                        )}
                      </>
                    );
                  })
                )}
              </ul>
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default DropdownComponent;
