/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  // Dropdown as DropDown,
  // Input,
  // MenuProps,
  Alert,
  Select,
  Tooltip,
  Checkbox,
  Row,
  Col,
  // SelectProps,
  // Space,
  Spin,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// import { Dropdown, IngredientDialog } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Page,
  Breadcrumb,
  ListViewWithoutExpand,
  // ResponseDialog,
} from '../../components';
// import IngredientActions from '../../redux/middleware/ingredient';
// import SubRecipeActions from '../../redux/middleware/subRecipe';
// import MenuCategoryActions from '../../redux/middleware/menuCategory';
// import Utils from '../../redux/utils';
import Delete from '../../assets/images/Delete.svg';
// import ArrowDown from '../../assets/images/ArrowDown.png';
// import Eye from '../../assets/images/Eye.svg';
import './RequisitionWizard.scss';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import SupplierApi from '../../redux/middleware/supplier';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { sortedData, sortNumber } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';

const RequisitionWizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  // const [loader, setLoader] = React.useState(false);
  // const company = Utils.getCurrentCompany();
  const [apiLoader, setApiLoader] = React.useState(false);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [spinner, setSpinner] = useState(true);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [errorList, setErrorList] = React.useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { update, id } = state;
  // const [menuCategory, setMenuCategory] = React.useState('');
  const [open, setOpen] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  const [ingredientName, setIngredientName] = useState('');
  // const [option, setOption] = useState<SelectProps['options']>([
  //   { value: '', label: '', key: '' },
  // ]);
  // const [quantity, setQuantity] = React.useState<number>();
  // const [ingredientData, setIngredientData] = useState<any[]>([]);
  const [requestData, setRequestData] = useState<any[]>([]);
  const [reqeustTo, setRequestTo] = useState<any>();
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      storage_unit: '',
      multiplier: 0,
    },
  ]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  // const [messages, setMessages] = useState<any[]>([]);
  // const [alerts, setAlerts] = useState(false);
  // const [visible, setVisible] = useState(false);
  // const [unit, setUnit] = useState([]);
  const { currentShop } = useSelector((state: any) => state.Auth);

  // const items: MenuProps['items'] = unit?.map((data: any) => {
  //   return {
  //     key: `${data?.id}`,
  //     label: <div className="dropdownOptions">{data?.unit}</div>,
  //   };
  // });

  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentShop?.shop_id);

  const onSelectMultiple = (e: CheckboxChangeEvent, record: any) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, record]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item.id !== record.id));
    }
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedItems(ingredients);
      setSelectAll(true);
    } else {
      setSelectedItems([]);
      setSelectAll(false);
    }
  };

  const isItemSelected = (record: any) => {
    return selectAll || selectedItems.some((item) => item.id === record.id);
  };

  const Columns = [
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>
            <Checkbox onChange={onSelectAll} checked={selectAll} />
          </span>
        </div>
      ),
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacing sub-view-align-adjustment">
          <Checkbox
            onChange={(e: any) => onSelectMultiple(e, record)}
            checked={isItemSelected(record)}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col1 cellSpacing font sub-view-align-adjustment">
            <div>
              <div className="subCol1 titleContainer">
                <span className="title">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Name</div>
                        <div className="tootipText">
                          {record?.ingredient_name}
                        </div>
                      </div>
                    }
                  >
                    {record?.ingredient_name?.length > 20
                      ? record?.ingredient_name?.substring(0, 20) + '...'
                      : record?.ingredient_name}
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>QUANTITY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('quantity', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing col-qty">
          <div className="gramSubContainer po-quantity">
            <input
              className="gramValue"
              placeholder="0"
              type={'number'}
              defaultValue={
                update
                  ? record.request_quantity
                  : record.quantity
                  ? Number(record.quantity).toString()
                  : ''
              }
              onChange={(e) => {
                if (+e.target.value <= 99999)
                  handleIngredientWeight(+e.target.value, index);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('storage_unit', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'storage_unit',
      key: 'storage_unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2 cellSpacing">{record?.storage_unit}</div>
      ),
    },
  ];

  useEffect(() => {
    if (update && currentBrandId !== currentShop?.shop_id) {
      navigate('/app/orderStock');
    }
  }, [currentBrand, currentShop]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const getIngredients = async () => {
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    setSpinner(true);
    const data = await SupplierApi.getInventoryForRequisition(
      3,
      '',
      brand?.brand_id ? brand?.brand_id : '',
      shop?.shop_id ? shop?.shop_id : ''
    );
    if (data != undefined && data?.success) {
      setIngredients(data?.data?.items);
      setTotal(data?.data?.pagination?.total);
      setSortData(data?.data?.items);
    } else {
      setIngredients([]);
      setSortData([]);
      setTotal(0);
    }
    setSpinner(false);
  };

  const fetchDataById = async (id: any) => {
    const { data, success } = await SupplierApi.getInventorySupplyById(id);

    if (success) {
      // setIngredients(
      //   data?.ingredients?.map((ingredient: any) => ({
      //     ...ingredient,
      //     quantity: ingredient?.request_quantity,
      //   }))
      // );
      setSortData(data?.ingredients);
      setIngredients(data?.ingredients);
      setRequestTo(data?.request_to_shop_id);
      setStatus({ status: data?.request_status, id: data?.request_status_id });
      setRequestType({
        status: data?.request_type_id,
        id: data?.request_type_id,
      });
      setApiLoader(false);
    }
  };

  const fetchRequestToOptions = async () => {
    const shop = await Utils.getCurrentShop();
    if (shop?.is_ck == 1) {
      setRequestData([{ label: shop?.shop_name, value: shop?.shop_id }]);
      setRequestTo(shop?.shop_id);
    } else {
      setRequestData([
        { label: shop?.ck_name, value: shop?.ck_id },
        { label: shop?.shop_name, value: shop?.shop_id },
      ]);
      setRequestTo(shop?.ck_id);
    }
  };

  // const fetchIngredients = async () => {
  //   const shop = await Utils.getCurrentShop();
  //   const brand = await Utils.getCurrentBrand();
  //   const { data, success } = await SubRecipeActions.getAllSubRecipeInventory(
  //     3,
  //     1,
  //     1000,
  //     brand?.brand_id ? brand?.brand_id : '',
  //     shop?.shop_id ? shop?.shop_id : ''
  //   );
  //   if (success) setIngredientData(data?.items);
  // };

  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(data?.find((type: any) => type?.slug == 'requisition'));

    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  // const fetchUnit = async () => {
  //   const data = await IngredientActions.getUnits();
  //   setUnit(data?.data);
  // };

  useEffect(() => {
    fetchRequestToOptions();
    if (!update) {
      getIngredients();
    }
  }, [currentShop, currentBrand]);
  useEffect(() => {
    checkAuthentication(user, navigate);
    // fetchUnit();
    // fetchIngredients();
    if (update) {
      setApiLoader(true);
      fetchDataById(id);
    } else {
      fetchStatus();
    }
  }, []);

  const handleRequestTo = (value: any) => {
    setRequestTo(value);
  };

  const handleContinue = async () => {
    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();

    const selectedIngredients = ingredients.filter((ingredient) =>
      selectedItems.some((item: any) => item.id === ingredient.id)
    );

    if (
      requestType?.id === undefined ||
      selectedItems.length == 0 ||
      selectedItems[0]?.ingredient_name == ''
    ) {
      setVisible(true);
      return;
    }

    setErrorList({});
    setErrorStatus(false);
    if (update === true) {
      const param = {
        request_type_id: requestType?.id,
        request_status_id: status?.id,
        request_from_brand_id: brand?.brand_id,
        request_to_shop_id: reqeustTo,
        company_id: company?.id,
        created_by: user?.id,
        ingredients: ingredients?.map((data: any) => ({
          ingredient_id: data?.ingredient_id || data?.id,
          request_quantity: data?.quantity,
        })),
        shop_id: shop?.shop_id,
        brand_id: brand?.brand_id,
      };
      // let responseDialogData;
      setApiLoader(true);

      try {
        const response = await SupplierApi.updateInventorySupply(param, id);
        setApiLoader(false);
        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
        } else {
          navigate('/app/orderStock');

          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/requisitions');
          //   },
          //   type: 'success',
          //   messageArray: [`Your Requisition`, ` has been Updated`],
          // };
          // ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const param = {
        request_type_id: requestType?.id,
        request_status_id: status?.id,
        request_from_brand_id: brand?.brand_id,
        request_to_shop_id: reqeustTo,
        company_id: company?.id,
        created_by: user?.id,
        ingredients: selectedIngredients?.map((data: any) => ({
          ingredient_id: data?.id,
          request_quantity: data?.quantity,
        })),
        shop_id: shop?.shop_id,
        brand_id: brand?.brand_id,
      };
      // let responseDialogData;
      setApiLoader(true);

      try {
        const response = await SupplierApi.createInventorySupply(param);
        setApiLoader(false);
        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
        } else {
          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/requisitions');
          //   },
          //   type: 'success',
          //   messageArray: [`Your Requisition`, ` has been Created`],
          // };
          // ResponseDialog(responseDialogData);
          navigate('/app/orderStock');
        }
      } catch (e) {
        console.log(e);
      }
    }
    // navigate('/app/requisitions');
  };

  // const checkEmptyIngredients = (index: number) => {
  //   if (index == 2) {
  //     const wrongIngredient = ingredients.filter((data: any) => {
  //       if (
  //         data?.ingredient_id == -1 ||
  //         data?.quantity == '' ||
  //         data?.quantity == '0'
  //       )
  //         return { unit: data?.carb_per_unit, qty: data?.quantity };
  //     });
  //     if (wrongIngredient.length) {
  //       setMessages([
  //         "Please Select correct Ingredient. Quantity shouldn't be zero and please remove unused fields, if any.",
  //       ]);
  //       setAlerts(true);
  //       setVisible(false);
  //       window.scrollTo(0, 0);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // const removeIngredientName = (index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].ingredient_name = '';
  //   setIngredients(() => [...dataArray]);
  // };

  // const handleIngredientName = (data: string, index: number) => {
  //   let dataArray = ingredients;

  //   dataArray[index].ingredient_name = data;

  //   setIngredients(() => [...dataArray]);
  // };

  // const handleRemoveIngredient = (index: number) => {
  //   let DataArray = ingredients;
  //   DataArray.splice(index, 1);
  //   setIngredients([...DataArray]);
  //   // autoFetchAllergen(DataArray);
  // };

  const handleIngredientWeight = (data: any, index: number) => {
    let dataArray = ingredients;
    if (data === 0) {
      dataArray[index].quantity = '';
      setIngredients(() => [...dataArray]);
    } else {
      dataArray[index].quantity = data;
      setIngredients(() => [...dataArray]);
    }
  };
  // const handleIngredientWeightUnit = (data: any, index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].unit = data?.unit;
  //   dataArray[index].unit_id = data?.id;
  //   dataArray[index].multiplier = data?.multiplier;

  //   setIngredients(() => [...dataArray]);
  // };

  // const AddIngredient = () => {
  //   setIngredients((prevArray) => [
  //     ...prevArray,
  //     {
  //       ingredient_id: -1,
  //       unit_id: 1,
  //       quantity: '',
  //       ingredient_name: '',
  //       storage_unit: 'Kg',
  //       multiplier: 0,
  //     },
  //   ]);
  // };

  // const handleIngredient = (data: any, index: number) => {
  //   let dataArray = ingredients;

  //   const checkDuplicateData = dataArray.some(
  //     (ingredient) => ingredient?.id === data?.id
  //   );

  //   const checkDuplicateField =
  //     dataArray.findIndex(
  //       (ingredientObject) => ingredientObject?.id === data?.id
  //     ) === index;

  //   if (!checkDuplicateField && checkDuplicateData) {
  //     dataArray.splice(index, 1);
  //     setIngredients(() => [...dataArray]);
  //     // setMessages([
  //     //   'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
  //     // ]);
  //     // setVisible(false);
  //     // setAlerts(true);
  //     window.scrollTo(0, 0);
  //     return;
  //   } else if (checkDuplicateField && checkDuplicateData) {
  //     setIngredients([...dataArray]);
  //   } else {
  //     // eslint-disable-next-line no-unused-vars
  //     // const unitObject = unit.filter(
  //     //   (element: any) => element?.id == data?.unit_id
  //     // );
  //     dataArray[index] = {
  //       ...data,
  //       quantity: '',
  //     };
  //     // autoFetchAllergen(dataArray);
  //     setIngredients(() => [...dataArray]);
  //   }
  // };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   const unitObject = unit.filter((data: any) => data?.id == option);
  //   handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  // };

  // const DropDownmenuProps = {
  //   items: items,
  //   onClick: handleDropDownClick,
  // };

  // const handleDialog = () => {
  //   setOpen(false);
  //   // fetchIngredients();
  //   removeIngredientName(ingredientIndex);
  // };

  // const handleDeleteContinue = (index: number) => {
  //   handleRemoveIngredient(index);
  // };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Requisition"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/orderStock');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Requisition">
          <div className="alertMessage">
            {visible && (
              <Alert
                message="Error"
                description="Please Complete required fields!"
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setVisible(false)}
              />
            )}

            {/*   {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))} */}
          </div>

          <div className="wizardSubContainer pocontainer">
            {/* <div className="wizardDropdown">
                <div className="wizardLabel">
                  Ingredient <span className="sterik">*</span>
                </div>
                <Select
                  size={'large'}
                  placeholder={'Select Ingredients'}
                  // onChange={handleCityChange}
                  style={{ width: 560 }}
                  // value={city?.name}
                  className={
                    errorList['ingredient_name']?.length > 0
                      ? 'dropdownUnitColorRed'
                      : ''
                  }
                >
                  {ingredientData?.map((data: any, index: number) => (
                    <Select.Option value={data?.id} key={index}>
                      {data.ingredient_name}
                    </Select.Option>
                  ))}
                </Select>
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['ingredient_name']}
                  </div>
                )}
              </div>
              <div className="wizardDropdown">
                <div className="wizardSubContainer">
                  <div className="wizardLabel">
                    Quantity <span className="sterik">*</span>
                  </div>
                  <Input
                    placeholder="Enter Menu Category Name"
                    size="large"
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      handleQuantity(+e.target.value);
                    }}
                    required
                    className={
                      errorList['name']?.length > 0 ? 'borderRed size' : 'size'
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['name']}
                    </div>
                  )}
                </div>
              </div> */}
            {/*  <div>
              {open ? (
                <IngredientDialog
                  handleDialog={handleDialog}
                  handleSuccesDialog={handleDialog}
                  open={open}
                  component={'marketlist'}
                  name={ingredientName}
                  handleIngredient={(data: any) => {
                    handleIngredient(data, ingredientIndex);
                    setIngredientName(data?.ingredient_name);
                  }}
                />
              ) : null}
              <div className={'wizardSubContainer'}>
                <div className="wizardLabel">
                  Add Items <span className="sterik">*</span>
                </div>

                {ingredients?.map((data: any, index: number) => (
                  <div className="gramContainer" key={index}>
                    <Dropdown
                      component={'supply'}
                      handleIngredientName={(text: any) => {
                        handleIngredientName(text, index);
                        setIngredientName(text);
                      }}
                      ingredientName={data?.ingredient_name}
                      openDialog={() => {
                        setIngredientIndex(index);
                        setOpen(true);
                      }}
                      ingredientData={[]}
                      database={{ label: 'USA', key: 3 }}
                      handleIngredient={(data: any) => {
                        handleIngredient(data, index);
                      }}
                    />

                    <div className="gramSubContainer">
                      <input
                        className="gramValue"
                        placeholder="0"
                        type={'number'}
                        value={
                          data.quantity ? Number(data?.quantity).toString() : ''
                        }
                        onChange={(e) => {
                          if (+e.target.value <= 99999)
                            handleIngredientWeight(+e.target.value, index);
                        }}
                      />
                      <div className="gramBoxWrapper">
                        <div className="gramBox">
                          {/* <DropDown
                              menu={DropDownmenuProps}
                              trigger={['click']}
                              placement="bottomRight"
                              arrow={{ pointAtCenter: true }}
                            >
                              <a onClick={() => setIngredientIndex(index)}>
                                <Space>
                                  <div>{data?.unit}</div>{' '}
                                  <img src={ArrowDown} alt="" />
                                </Space>
                              </a>
                            </DropDown>
                          <div>{data?.storage_unit}</div>
                        </div>
                      </div>
                    </div>

                    <img
                      src={Delete}
                      width={15}
                      alt=""
                      className="deleteButon"
                      onClick={() => {
                        // handleIndex(index);
                        handleDeleteContinue(index);
                        // data?.ingredient_name == ''
                        //   ? handleDeleteContinue(index)
                        //   : setOpenDelete(true);
                      }}
                    />
                    {/* <div className="calorie-container-each-ingredient">
                <div className="">Calories</div>
                <div className="calorie-each-value-container">
                  {isNaN(data?.calories_per_unit * parseFloat(data?.quantity))
                    ? 0
                    : Number(
                        (
                          data?.calories_per_unit * parseFloat(data?.quantity)
                        ).toFixed(2)
                      )}
                </div>
                <Popover
                  content={
                    <div className="popUp-recipe-Container">
                      <div className="block block1">
                        <div>
                          <span className={`callories-number `}>
                            {isNaN(
                              data?.calories_per_unit *
                                parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.calories_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font callories-unit">{'kcal'}</span>
                        </div>
                        <div>
                          <span className={`carb-number `}>
                            {isNaN(
                              data?.carb_per_unit * parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.carb_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font carb-unit">{'g'}</span>
                        </div>
                        <div>
                          <span className={`fat-number `}>
                            {isNaN(
                              data?.fat_per_unit * parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.fat_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font fat-unit">{'g'}</span>
                        </div>
                        <div>
                          <span className={`font protein-number `}>
                            {isNaN(
                              data?.protein_per_unit *
                                parseFloat(data?.quantity)
                            )
                              ? 0
                              : Number(
                                  (
                                    data?.protein_per_unit *
                                    parseFloat(data?.quantity)
                                  ).toFixed(2)
                                )}
                          </span>
                          <span className="font protein-unit">{'g'}</span>
                        </div>
                      </div>
                      <div className="block block2">
                        <span className={'callories-text'}>calories</span>
                        <span className={'callories-text'}>carbs</span>
                        <span className={'callories-text'}>fats</span>
                        <span className={'callories-text'}>proteins</span>
                      </div>
                      <div className="allergen font">Allergens</div>
                      <div className="allergen-text">{data?.allergen}</div>
                    </div>
                  }
                  title="Details"
                  trigger="click"
                  open={popUp == index}
                  onOpenChange={() => {
                    if (popUp >= -1) setPopUp(-2);
                    else setPopUp(index);
                  }}
                >
                  <img
                    src={Eye}
                    width={20}
                    className="ingredient-unmatach-select-icons"
                  />
                </Popover>
              </div> 
                  </div>
                ))}
                {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} 
              </div>

            {/* <div className="addButton">
                <div onClick={AddIngredient} className="addButton-subContainer">
                  + Add New Row
                </div>
              </div> 
            </div> */}
            <div className="wizardDropdown">
              <div className="wizardLabel">Request Sent to CK Branch</div>
              <Select
                size={'large'}
                placeholder={'Select Request to'}
                // disabled={requestData?.length == 1}
                disabled
                options={requestData}
                onChange={handleRequestTo}
                style={{ width: 560 }}
                value={reqeustTo}
                className={
                  errorList['request']?.length > 0 ? 'dropdownUnitColorRed' : ''
                }
              />

              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['request']}
                </div>
              )}
            </div>
          </div>
          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={16} className="requisition-list-wrapper">
              {!apiLoader ? (
                <ListViewWithoutExpand
                  limit={1000}
                  items={''}
                  dataSource={[...sortData]}
                  columns={Columns}
                  component="stock request"
                  scrollY={600}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin
                    className="loadIngredient recipeBuilderloaderHeight"
                    spinning={apiLoader}
                  />
                </div>
              )}
            </Col>
          </Row>
          <div className="butonGroup po-btn">
            <div
              className="wizard-button"
              onClick={() => navigate('/app/orderStock')}
            >
              Back
            </div>
            <div className="continue wizard-button" onClick={handleContinue}>
              Continue
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default RequisitionWizard;
