/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
  Modal,
} from 'antd';
import More from '../../assets/images/More.png';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Eye from '../../assets/images/Eye.svg';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  UserList,
  DetailDialog,
} from '../../components';
// import {
//   Dropdown as DropDown,
//   // Input,
//   MenuProps,
//   Select,
//   // SelectProps,
//   Space,
//   Spin,
// } from 'antd';
import PurchaseApi from '../../redux/middleware/purchaseOrder';
import { sortNumber, sortedData } from '../../helper/sortData';
import Edit from '../../assets/images/Edit.png';
import SupplierApi from '../../redux/middleware/supplier';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import './purchaseOrder.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">View</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '2',
    label: <div className="dotOption modifierDropdownLabels">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  // {
  //   key: '3',
  //   label: <div className="dotOption modifierDropdownLabels">Accept</div>,
  //   icon: <img src={Eye} width={15} />,
  // },
];

const UTCtoLocal = (utcDate: any) => {
  var stillUtc = moment.utc(utcDate).toDate();
  var local = moment(stillUtc).local();
  return local;
};

const PageDataLength = [6, 10, 20, 50];

const PurchaseOrderView = () => {
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [purchaseOrder, setPurchaseOrder] = useState([{}]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [userLoader, setUserLoader] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [id, setid] = useState();
  const [loader, setLoader] = useState(false);
  const [acceptPO, setAcceptPO] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [search, setSearch] = useState('');
  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          Active
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          Non-active
        </div>
      ),
    },
  ];
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentShop?.shop_id);
  useEffect(() => {
    if (currentBrandId !== currentShop?.shop_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      //   UsersApi.disableUser(id, '1');
      pageData();
    } else {
      //   UsersApi.disableUser(id, '0');
      pageData();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setAcceptPO(false);
  };

  const userColumns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>PO ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => {
        return (
          <div
            className="id table-title"
            onClick={() => {
              setLoader(true);
              fetchDialogData(record?.id);
            }}
          >
            {/* {record?.id} */}
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">ID</div>
                  <div className="tootipText">{record?.id}</div>
                </div>
              }
            >
              <div className="subrecipesubCol2 ">{record?.id}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{user?.roles[0]?.id === 4 ? 'BRANCH NAME' : 'BRAND'}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('po_from_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'po_from_name',
      key: 'po_from_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">
            <Tooltip
              title={
                <div>
                  <div className="tootipText">{record?.po_from_name}</div>
                </div>
              }
            >
              {record?.po_from_name?.substring(0, 22)}
              {record?.po_from_name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          </div>
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>Supplier</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('supplier_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'supplier_name',
      key: 'supplier_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">
            {record?.supplier_name?.length > 22 ? (
              <Tooltip
                title={
                  <div>
                    <div className="tootipText">{record?.supplier_name}</div>
                  </div>
                }
              >
                {record?.supplier_name?.substring(0, 22)}
                {record?.supplier_name?.length > 22 ? <span>...</span> : null}
              </Tooltip>
            ) : (
              record?.supplier_name
            )}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Status</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('request_status', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'request_status',
      key: 'request_status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <div className="company">{record?.request_status}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Created</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Created</div>
                  <div className="tootipText">
                    {moment(UTCtoLocal(record?.created_at)).format(
                      'DD-MM-YYYY HH:mm:ss'
                    )}
                  </div>
                </div>
              }
            >
              <div className="subrecipesubCol2 ">
                {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Approved</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('approved_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'approved_at',
      key: 'approved_at',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">
            {record?.approved_at ? (
              <Tooltip
                title={
                  <div>
                    <div className="tooltipHead">Approved</div>
                    <div className="tootipText">
                      {moment(UTCtoLocal(record.approved_at)).format(
                        'DD-MM-YYYY HH:mm:ss'
                      )}
                    </div>
                  </div>
                }
              >
                <div className="subrecipesubCol2">
                  {moment(UTCtoLocal(record.approved_at)).format('DD-MM-YYYY')}
                </div>
              </Tooltip>
            ) : (
              <div className="subrecipesubCol2">{''}</div>
            )}
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Received</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('received_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'received_at',
      key: 'received_at',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="company">
            {record?.received_at ? (
              <Tooltip
                title={
                  <div>
                    <div className="tooltipHead">Received</div>
                    <div className="tootipText">
                      {moment(UTCtoLocal(record?.received_at)).format(
                        'DD-MM-YYYY HH:mm:ss'
                      )}
                    </div>
                  </div>
                }
              >
                <div className="subrecipesubCol2 ">
                  {moment(UTCtoLocal(record?.received_at)).format('DD-MM-YYYY')}
                </div>
              </Tooltip>
            ) : (
              <div className="subrecipesubCol2">{''}</div>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="PO-accept-button-container">
          {record?.request_status?.toLowerCase() == 'pending' && (
            <Button
              text="Review"
              className="theme-button recipeBtn uploadMarketListButton"
              handleButtonClick={() => {
                setAcceptPO(true);
                setLoader(true);
                setSelectedMenu(record?.id);
                fetchDialogData(record?.id);
              }}
              shape="round"
              size="middle"
            />
          )}
          <Dropdown
            menu={{
              onClick: handleDropDownClick,
              items:
                record?.request_status?.toLowerCase() !== 'pending'
                  ? items.filter((x) => x?.key === '1')
                  : items,
            }}
            trigger={['click']}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <a
              onClick={() => {
                setSelectedMenu(record?.id);
              }}
            >
              <Space>
                <div className="requisitions-dots">
                  <img src={More} />
                </div>
              </Space>
            </a>
          </Dropdown>
        </div>
      ),
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (poid: any) => {
    setSelectedUserId(poid);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Purchase Order'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const fetchDialogData = async (id: any) => {
    const { data, success } = await SupplierApi.getInventorySupplyById(id);
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const handleEditPO = async () => {
    navigate('wizard', { state: { update: true, id: selectedUserId } });
  };

  const handleAcceptPO = async () => {
    setLoader(true);
    const response = await PurchaseApi.acceptPO(
      {
        request_status_id: 8,
      },
      selectedUserId
    );
    if (response?.success) pageData();
    setLoader(false);
    handleClose();
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setLoader(true);
        fetchDialogData(selectedUserId);
        break;

      case 2:
        navigate('wizard', { state: { update: true, id: selectedUserId } });
        break;
      case 3:
        setAcceptPO(true);
        setLoader(true);
        fetchDialogData(selectedUserId);
        break;

      default:
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        if (shop) {
          navigate('wizard', { state: { update: false, id: '' } });
        } else {
          setIsModalVisible(true);
        }
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setUserLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    let request_id;
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'purchase_order'
      );
    const poData = await SupplierApi.searchInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      currentBrand?.brand_id || brand?.brand_id,
      keyword
    );
    if (poData === undefined || !poData?.success) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(poData?.data?.supply_count);
      setTotal(poData?.data?.pagination?.total);
      setPurchaseOrder(poData?.data?.items);
      setSortData(poData?.data?.items);
    }
    setUserLoader(false);
  };

  const onSearch = async (keyword: string) => {
    // setSearch(keyword);
    setUserLoader(true);
    // const shop = await Utils.getCurrentShop();
    // const brand = await Utils.getCurrentBrand();
    // let request_id;
    // const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    // if (success)
    //   request_id = data?.find(
    //     (supply: any) => supply?.slug == 'purchase_order'
    //   );
    // const searchResultofRecipes = await SupplierApi.searchInventorySupply(
    //   request_id?.id,
    //   dataLength,
    //   current,
    //   shop?.shop_id ? shop?.shop_id : '',
    //   brand?.brand_id ? brand?.brand_id : '',
    //   keyword
    // );
    // if (searchResultofRecipes === undefined) {
    //   setUserCount(0);
    //   setTotal(0);
    //   setSortData([]);
    // } else {
    //   setUserCount(searchResultofRecipes?.data?.supply_count);
    //   setTotal(searchResultofRecipes?.data?.pagination?.total);
    //   setSortData(searchResultofRecipes?.data?.items);
    // }
    if (keyword) {
      const results = sortData.filter(
        (obj: any) =>
          obj.po_from_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.supplier_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.request_status?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.created_at?.includes(keyword)
      );
      setSortData(results);
    } else {
      setSortData(purchaseOrder);
    }
    setUserLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Purchase Order"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Purchase Order">
          <DetailDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
            acceptPO={acceptPO}
            handleAcceptPO={handleAcceptPO}
            handleEditPO={handleEditPO}
            component={'purchase_order'}
          />
          <Row className="listingTopFilter">
            <Col lg={10} md={24}>
              <ListCount count1={userCount} title1="Total Purchase Order" />
            </Col>
            <Col lg={14} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText="Search..."
                  />
                </div>
                {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export</div>
              </div>
              */}
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!userLoader ? (
                <UserList
                  dataSource={[...sortData] || []}
                  userColumns={userColumns}
                  limit={dataLength}
                />
              ) : (
                // <ListViewWithoutExpand
                //   items={items}
                //   dataSource={[...sortData]}
                //   columns={columns}
                // />
                <div className="loader-wrapper">
                  <Spin spinning={userLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
      <Modal
        title={<div className="title font">Purchase Order</div>}
        centered
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={480}
        footer={null}
        className="deleteConfirmationContainer"
      >
        <div className="deleteDialogText">
          Please create a branch before adding a new Purchase Order
        </div>
        <div className="butonGroupDialog">
          <div
            className="wizard-button"
            onClick={() => setIsModalVisible(false)}
          >
            Ok
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PurchaseOrderView;
